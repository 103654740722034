import {FunctionComponent} from 'react';
import PageHeader from '../../components/template/PageHeader';
import PageFooter from '../../components/template/PageFooter';
import PageContainer from '../../components/template/PageContainer';
import Container from '../../components/common/Container';
import CircleIcon from '../../components/common/CircleIcon';
import AgentServiceCards from '../../components/services/AgentServiceCards';
import PriceChartContent from '../../components/content/PriceChart';

const AgentServicesPage: FunctionComponent = () => {
  return (
    <PageContainer>
      <PageHeader/>
      <Container isSection={true} safeSides={true}>
        <h2><CircleIcon iconClass="fa-solid fa-user"></CircleIcon> AGENT and BROKER SERVICES</h2>
        <AgentServiceCards/>
      </Container>
      <Container isSection={true} className="txt-center" safeSides={true}>
        <PriceChartContent/>
      </Container>
      <PageFooter/>
    </PageContainer>
  );
}

export default AgentServicesPage;