import {FunctionComponent} from 'react';
import './ServiceCard2.css';

type ServiceCard2Props = {
    title: string;
    description: string;
    image: string
};
const ServiceCard2: FunctionComponent<ServiceCard2Props> = ({title, description, image}) => {
    return (
        <div className="ServiceCard2">
            <div className="ServiceCard2__image" style={{backgroundImage:'url('+image+')'}}>
                <div className="ServiceCard2__info">
                    <h4>{title}</h4>
                    <main>{description}</main>
                </div>
            </div>
        </div>
    );
};

export default ServiceCard2;