import {FunctionComponent, useState} from 'react';
import Box from '../common/Box';
import YesNoQuestion from '../common/YesNoQuestion';
import {AudienceType} from '../../enums/audience-type';
import {useNavigate} from 'react-router-dom';
import {Audience} from '../../types/audience';
import Container from '../common/Container';
import {imagePath} from '../../utils/paths';

type ServiceSelectorProps = {};

const ServiceSelector: FunctionComponent<ServiceSelectorProps> = () => {
  const [audience, setAudience] = useState<Audience>({
    type: undefined,
    isOfType: undefined
  });

  const handleSetAudience = (audience: Audience) => {
    setAudience(audience);
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  const nav = useNavigate();
  return audience.type === undefined ?
    (
      <div className="row" style={{gap: 20}}>
        <Box className="col6">
          <YesNoQuestion question="Broker or Agent?"
                         preText="Are you a Real Estate"
                         iconClass="fa-solid fa-user"
                         onYes={() => {
                           nav('/services/agents');
                         }}
                         onNo={() => {
                           handleSetAudience({
                             type: AudienceType.Agent,
                             isOfType: false
                           });
                         }}
          />
        </Box>

        <Box className="col6">
          <YesNoQuestion question="Escrow or Title Company?"
                         preText="Are you the owner or manager of an"
                         iconClass="fa-solid fa-building"
                         onYes={() => {
                           nav('/services/owners')
                         }}
                         onNo={() => {
                           handleSetAudience({
                             type: AudienceType.Company,
                             isOfType: false
                           });
                         }}
          />
        </Box>
      </div>
    ) : (
      <>
        {/*<Container isSection={true}>*/}
        {/*  <Button variation={ButtonVariation.Normal} onClick={() => handleSetAudience({type: undefined, isOfType: false})}><i*/}
        {/*    className="fa-solid fa-arrow-left"/> Back to Service Selection</Button>*/}
        {/*</Container>*/}
        <Container isSection={true}>
          {audience.type === AudienceType.Agent && (
            <div style={{maxWidth: 600, margin: '50px auto'}}>
              <Box className="relative">
                <div style={{paddingRight: 200}}>
                  If you are needing For Sale By Owner (FSBO) Services, please contact us for more details.
                </div>
                <img src={imagePath('/services/agent/AdobeStock_179561942.png')} alt="Couple with real estate agent"
                     className="absolute" style={{width: 200, right: 20, bottom: -15}}/>
              </Box>
            </div>
          )}
          {audience.type === AudienceType.Company && (
            <div style={{maxWidth: 600, margin: '140px auto'}}>
              <Box className="relative">
                <div style={{paddingRight: 200}}>
                  We process hundreds of files each month for some of the best Escrow and Title Companies in the USA. If you need a referral to one, let us know!
                </div>
                <img src={imagePath('/services/owner/AdobeStock_313778126-Adjusted.png')} alt="Happy business owner"
                     className="absolute" style={{width: 180, right: 20, bottom: -60}}/>
              </Box>
            </div>
          )}
        </Container>
      </>
    );
}

export default ServiceSelector;