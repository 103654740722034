import {FunctionComponent, useState} from 'react';
import Modal from './Modal';
import ModalContent from './ModalContent';
import Button, {ButtonVariation} from '../common/Button';
import TextInput from '../form/TextInput';
import './ContactFormModal.css';
import ErrorList from '../common/ErrorList';
import {BasicContactFormData} from '../../types/contact-data';
import sendContactData from '../../services/contact-form';
import BusyIndicator from '../common/BusyIndicator';
import FormRow from '../form/FormRow';

type ContactFormModalProps = {
  onDone: (contactData: BasicContactFormData) => void;
  subject: string;
};

enum ContactFormState {
  Normal,
  Errors,
  Sending,
  Sent
}
const ContactFormModal: FunctionComponent<ContactFormModalProps> = ({onDone, subject}) => {
  const [state, setState] = useState<ContactFormState>(ContactFormState.Normal);
  const [contactInfo, setContactInfo] = useState<BasicContactFormData>({
    name: '',
    phone: '',
    email: '',
    message: '',
    subject
  });

  const [errors, setErrors] = useState<string[]>([]);
  const isValidEmail = (email: string) => email.length > 0 && email.match(/.*@.*\..*/);

  return (
    <Modal title="Contact Us" className="ContactFormModal">
      <ModalContent>
      {/*<main>*/}
      {/*  <div className="ContactFormModal__image" style={{backgroundImage: 'url(' + imagePath('/contact/AdobeStock_497677788.jpg') + ')'}}/>*/}
      {/*  <div className="ContactFormModal__form">*/}
          <div className="flex" style={{justifyContent: 'center'}}>
            <form>
              <ErrorList errors={errors}/>
              <p><strong>Subject: </strong> {subject}</p>
              {state === ContactFormState.Sending && <><BusyIndicator/> Sending</>}
              {state === ContactFormState.Sent && (
                <div className="txt-center">
                  <i className="fa-solid fa-thumbs-up txt-large"/>

                  <p>Received.  Thank You</p>
                  <Button variation={ButtonVariation.Normal} onClick={() => {
                    onDone(contactInfo);
                  }}>Close</Button>
                </div>
              )}

              {state !== ContactFormState.Sent && (
                <>
                  <FormRow>
                    <TextInput value={contactInfo.name}
                               onChange={name => setContactInfo({...contactInfo, name})}
                               placeholder="Name" isError={contactInfo.name.length === 0}/>
                  </FormRow>
                  <FormRow>
                    <TextInput value={contactInfo.email}
                               onChange={email => setContactInfo({...contactInfo, email})}
                               isError={contactInfo.email.length === 0 || !isValidEmail(contactInfo.email)}
                               placeholder="Email"/>
                  </FormRow>
                  <FormRow>
                    <TextInput value={contactInfo.phone}
                               onChange={phone => setContactInfo({...contactInfo, phone})}
                               isError={contactInfo.phone.length === 0}
                               placeholder="Phone"/>
                  </FormRow>
                  <FormRow>
                    <TextInput type="textarea"
                               value={contactInfo.message}
                               onChange={message => setContactInfo({...contactInfo, message})}
                               placeholder="Message"/>
                  </FormRow>

                  <Button disabled={state === ContactFormState.Sending} variation={ButtonVariation.Primary} onClick={() => {
                    setErrors([]);
                    const tErrors: string[] = [];
                    if (contactInfo.name.length === 0) tErrors.push('Name is required');
                    if (contactInfo.email.length === 0) tErrors.push('Email is required');
                    else if (!isValidEmail(contactInfo.email)) tErrors.push('Invalid email');
                    if (contactInfo.phone.length === 0) tErrors.push('Phone is required');
                    if (tErrors.length > 0) {
                      setErrors(tErrors);
                    } else {
                      setState(ContactFormState.Sending);
                      sendContactData(contactInfo).then(response => {
                        if (response.success) {
                          setState(ContactFormState.Sent);
                        } else {
                          setState(s => ContactFormState.Errors);
                        }
                      }).catch(e => {
                        setState(ContactFormState.Errors);
                        setErrors(['An unknown error occurred']);
                      });
                    }
                  }}>Submit</Button>
                </>
              )}
            </form>
          </div>
        {/*</div>*/}
      {/*</main>*/}
      </ModalContent>
    </Modal>
  );
}

export default ContactFormModal;