import {FunctionComponent, PropsWithChildren, useContext, useState} from 'react';
import Container from "../common/Container";
import Button, {ButtonVariation} from "../common/Button";
import TextInput from "../form/TextInput";
import isValidEmail from "../../utils/string";
import Box from "../common/Box";
import './OwnerServiceWizard.css';
import CheckmarkBullet, {Type} from "../common/CheckmarkBullet";
import {imagePath} from "../../utils/paths";
import DescriptionCard from "../common/DescriptionCard";
import CircleIcon from '../common/CircleIcon';
import AppContext from '../../contexts/AppContext';
import ContactFormModal from '../modal/ContactFormModal';
import sendContactData from '../../services/contact-form';
import ErrorList from '../common/ErrorList';

type OwnerServiceWizardProps = PropsWithChildren;

enum SubmissionState {
    Initial,
    Sending,
    Submitted
}

const OwnerServiceWizard: FunctionComponent<OwnerServiceWizardProps> = ({children}) => {
    const [state, setState] = useState<SubmissionState>(SubmissionState.Initial);
    const [errors, setErrors] = useState<string[]>([]);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');

    const isInputValid = (): boolean => {
        return name.length > 0 && email.length > 0 && phone.length > 0 && isValidEmail(email);
    };

    const appContext = useContext(AppContext);

    return <div className="OwnerServiceWizard">
        {errors.length > 0 && (
          <Container>
              <ErrorList errors={errors}/>
          </Container>
        )}
        {
            state === SubmissionState.Submitted ? (
                <>
                    <Container>
                        <div className="row">
                            <div className="col8" style={{marginLeft: 'auto', marginRight: 'auto'}}>
                                <DescriptionCard>
                                    <div className="txt-center">TPS, Inc. saves you money in processing and employee costs.</div>
                                </DescriptionCard>
                            </div>
                        </div>
                        {/* Center connector */}
                        <div className="row">
                            <div className="col6 connect-vert-mid">&nbsp;</div>
                        </div>
                        {/* Two connector down */}
                        <div className="row">
                            <div className="col6 connect-2vert-down">&nbsp;</div>
                        </div>

                        <div className="row relative" style={{gap: 20}}>
                            <div className="col6">
                                <DescriptionCard>Because the real estate industry is volatile owners and managers of title and escrow are forced to hire when things get busy which involves onboarding, training and managing only to lay off those same people when things slow down.</DescriptionCard>
                            </div>
                            <div className="col6">
                                <DescriptionCard>You are the owner/manager of a small independent company that prides itself on riding out the volatility of the market and retaining your full staff which means you sacrifice your profits and put your company at financial risk each time things slow down.</DescriptionCard>
                            </div>
                            <div className="or-circle">OR</div>
                        </div>
                        {/* Connector */}
                        <div className="row">
                            <div className="col6 connect-2vert-up">&nbsp;</div>
                        </div>
                        <div className="row">
                            <div className="col6 connect-vert-mid">&nbsp;</div>
                        </div>
                        <div className="row m-b">
                            <div className="col6" style={{marginLeft: 'auto', marginRight: 'auto'}}>
                                <DescriptionCard>If you're tired of dealing with this aspect of the industry and the affect it has on your business then TPS, Inc. is your solution and you should continue to read.</DescriptionCard>
                            </div>
                        </div>
                    </Container>


                    <div style={{backgroundColor: 'white', padding: 50, margin: '50px 0', boxShadow: '0 0 20px rgba(0, 0, 0, 0.05)'}}>
                        <Container>
                            <div className="row" style={{gap: 20}}>
                                <div className="col3 txt-center">
                                    <div style={{boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', padding: 20}}>
                                        <div><CircleIcon iconClass="fa-solid fa-tag"/></div>
                                        <div style={{margin: '20px 0'}}>
                                            <span style={{fontSize: '1.8em', lineHeight: '1em'}}>FLAT</span><br/>
                                            <span style={{fontSize: '3em', lineHeight: '1em'}}>$500</span><br/>
                                            ALL PROCESSING<br/>
                                            FOR THE FILE!
                                        </div>
                                        <Button variation={ButtonVariation.Primary} onClick={() => {
                                            appContext.modalManager.pushModal(
                                              <ContactFormModal subject="Interested in $500 flat processing" onDone={() => {
                                                                  appContext.modalManager.popAll();
                                                                }}
                                              />
                                            );
                                        }}>Start Today</Button>
                                    </div>
                                </div>
                                <div className="col9" style={{paddingTop: 20}}>
                                    <ul className="unlist separated">
                                        <li>
                                            <CheckmarkBullet><span style={{fontSize: '1.4em', lineHeight: '1em'}}>One low cost to provide all processing on the transaction for a flat $500 per file fee.</span></CheckmarkBullet>
                                            <ul className="unlist separated">
                                                <li><CheckmarkBullet type={Type.Secondary}>By freeing up your teams time to focus on the customer you are immediately enhancing the key to your businesses success, which is Customer Service.</CheckmarkBullet></li>
                                            </ul>
                                        </li>
                                        <li><CheckmarkBullet>ADDED BONUS to working with TPS, Inc.  TPS, Inc. will offer a flat Transaction Coordination service to the real estate agents involved on any file TPS, Inc. Is actively providing processing for a flat TC fee of $98 which in turn could save the agent over $400 per transaction</CheckmarkBullet></li>
                                    </ul>
                                </div>

                            </div>
                        </Container>
                    </div>

                    <Container isSection={true} safeSides={true} className="Container--narrow">
                        <div className="CheckList">
                            <ul className="unlist separated">
                                <li>
                                    <CheckmarkBullet>TPS, Inc. offers you a per file cost for all your processing needs.</CheckmarkBullet>
                                    <ul className="unlist separated">
                                        <li><CheckmarkBullet type={Type.Secondary}>No overhead for full time employee.</CheckmarkBullet></li>
                                        <li><CheckmarkBullet type={Type.Secondary}>Immediately save on the cost of salaries, benefits, training, employment taxes, computer equipment and office space.</CheckmarkBullet></li>
                                        <li><CheckmarkBullet type={Type.Secondary}>Limit your employee HR risks by outsourcing the busy work.</CheckmarkBullet></li>
                                        <li><CheckmarkBullet type={Type.Secondary}>Only pay when you actually have a file that will produce revenue.</CheckmarkBullet></li>
                                    </ul>
                                </li>
                                <li>
                                    <CheckmarkBullet>TPS, Inc. can provide the following services on a per file cost:</CheckmarkBullet>
                                    <ul className="unlist separated">
                                        <li><CheckmarkBullet type={Type.Secondary}>Data Entry.  Let your escrow officer or title officer focus on the management of the transaction and communication with the consumer and real estate agents and not get overwhelmed with the “busy” work, Let us handle that.</CheckmarkBullet></li>
                                        <li><CheckmarkBullet type={Type.Secondary}>Tax lien acquisition.  Instead of employing someone to sit on the phone with the IRS or State for 3+ hours to obtain the tax lien info you need let us handle that.</CheckmarkBullet></li>
                                        <li><CheckmarkBullet type={Type.Secondary}>Mortgage payoff demand, Solar liens, Child support, Credit card pay off demands. let us handle that.  We will call, schedule, calendar and follow up to ensure everything your officers need to close on the transaction is in their file to close.</CheckmarkBullet></li>
                                        <li><CheckmarkBullet type={Type.Secondary}>Your team will have continuous access to the processing team for questions and will be kept up to date in real time.</CheckmarkBullet></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </Container>
                </>
            ) : (
                <div style={{backgroundImage: 'xurl('+imagePath('/services/owner/pexels-sora-shimazaki-5668858-optimized.jpg')+')', backgroundPosition: 'center center', backgroundSize: 'cover'}}>
                    <Container>
                        <div className="row">
                            <div className="col6" style={{margin: '0 auto'}}>
                                <Box>
                                    <h3>See the benefits</h3>
                                    <p>Enter your name and email below and learn more about our service offerings.</p>
                                    <form>
                                        <p><TextInput value={name}
                                                      placeholder="Name"
                                                      onChange={val => setName(val)}
                                                      isError={name.length === 0}
                                        /></p>
                                        <p><TextInput value={email}
                                                      placeholder="Email"
                                                      onChange={val => setEmail(val)}
                                                      isError={email.length === 0 || !isValidEmail(email)}
                                        /></p>
                                        <p><TextInput value={phone}
                                                      placeholder="Phone"
                                                      onChange={val => setPhone(val)}
                                                      isError={phone.length === 0}
                                        /></p>

                                        <Button variation={ButtonVariation.Primary}
                                                onClick={() => {
                                                    setState(SubmissionState.Sending);
                                                    setErrors([]);
                                                    sendContactData({
                                                        name,
                                                        email,
                                                        phone,
                                                        subject: 'Escrow or Title Company Owner Submission',
                                                        message: '[A visitor submitted this request to enter the "Owner Benefits" section]'
                                                        // setState(SubmissionState.Submitted)
                                                    }).then(response => {
                                                        if (response.success) {
                                                            setState(SubmissionState.Submitted);
                                                        } else {
                                                            setState(SubmissionState.Initial);
                                                            setErrors(response.errors)
                                                        }
                                                    }).catch(e => {
                                                        setState(SubmissionState.Initial);
                                                        setErrors(['An unknown error occurred']);
                                                        window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
                                                    })
                                                }}
                                                disabled={!isInputValid() || state === SubmissionState.Sending}
                                        >
                                            Let's Go
                                        </Button>
                                    </form>
                                </Box>
                            </div>
                        </div>
                    </Container>
                </div>
            )
        }
    </div>
};

export default OwnerServiceWizard;