import {FunctionComponent} from 'react';
import {Route, Routes} from 'react-router-dom';
import PageNotFound from '../PageNotFound';
import OwnerServicesPage from './Owners';
import AgentServicesPage from './Agents';
import ServicesHomePage from './ServicesHome';

const ServiceIndexPage: FunctionComponent = () => {
  return (
    <Routes>
      <Route index element={<ServicesHomePage/>}/>
      <Route path="agents" element={<AgentServicesPage/>}/>
      <Route path="owners" element={<OwnerServicesPage/>}/>
      <Route path="*" element={<PageNotFound/>}/>
    </Routes>
  );
}

export default ServiceIndexPage;