import {FunctionComponent, PropsWithChildren} from 'react';
import './DescriptionCard.css';

type DescriptionCardProps = PropsWithChildren;

const DescriptionCard: FunctionComponent<DescriptionCardProps> = ({children}) => {
    return (
        <div className="DescriptionCard">
            {children}
        </div>
    );
}

export default DescriptionCard;
