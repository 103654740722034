import config from '../config/config';
import {FormType} from '../types/forms';

type FormSubmissionResponse = {
  success: boolean
  errors: string[]
  fieldErrors: {[key: string]: string[]}
  // submissionId: string
  auth?: SubmissionAuth
}

function getForm(id: string): Promise<FormType> {
  return fetch(config.apiBaseUrl + '/forms/' + id).then(response => response.json()).then((json: {form:FormType}) => json.form);
}

type KeyedObject<T=any> = {[key: string]: T};
export type SubmissionAuth = {id: string, auth: string};
type OnStatusCallback = (status: string, submission?: SubmissionAuth) => void;

/**
 * Submits a form to the server:
 *   1. Perform initial validation check
 *   2. Submit the form data
 *   3. Upload any files
 * @param {FormType} form
 * @param data
 * @param {OnStatusCallback} onStatus
 * @param {SubmissionAuth} auth
 */
function submitForm(form: FormType,
                    data: { [key: string]: any },
                    onStatus: OnStatusCallback,
                    auth?: SubmissionAuth
): Promise<FormSubmissionResponse> {
  const [values, files] = separateFilesAndValues(data);
  const initialErrors = validateForm(form, values);

  // Check for initial errors before submitting
  if (initialErrors.length > 0) {
    return Promise.resolve({
      success: false,
      errors: initialErrors,
      fieldErrors: {},
      submissionId: ''
    });
  }

  // Update the caller status
  onStatus('Submitting...');

  // If we have an auth to update an existing endpoint then use the update endpoint
  const endPoint = auth ? '/forms/' + form.id + '/submissions/' + auth.id : '/forms/' + form.id;
  const body: {complete: boolean, values: {[key: string]: any}, auth?: string} = {
    complete: Object.keys(files).length === 0, // If no files then submission is complete
    values
  };
  if (auth) body.auth = auth.auth;

  // Send request
  return fetch(config.apiBaseUrl + endPoint, {
    method: auth ? 'PUT' : 'POST',
    body: JSON.stringify(body)
  }).then(response => response.json())
    .then((json:FormSubmissionResponse) => {
      onStatus('Submission sent', json.auth); // Update with any new auth
      if (!json.success || Object.keys(files).length === 0) return json;
      if (!json.auth) throw new Error('No auth returned for file upload');

      return uploadSubmissionFiles(form, json.auth, files, onStatus);
    });
}

/**
 * Upload one file at a time, working through each upload
 * field and all files being uploaded for that field
 * @param {FormType} form
 * @param {SubmissionAuth} submissionAuth
 * @param {KeyedObject} files
 * @param {OnStatusCallback} onStatus
 * @param {number} fileElementIx
 * @param {number}fileIx
 */
function uploadSubmissionFiles(form: FormType, submissionAuth: SubmissionAuth, files: KeyedObject<Array<File>>, onStatus: OnStatusCallback, fileElementIx: number = 0, fileIx: number = 0): Promise<FormSubmissionResponse> {
  const field = Object.keys(files)[fileElementIx];
  // Let caller know what we are doing
  const statusMessage = 'Uploading ' + files[field][fileIx].name;
  // console.log('UPLOADING:', submissionAuth);
  onStatus(statusMessage, submissionAuth);
  const formData = new FormData();
  formData.append('field', field);
  formData.append('num', fileIx.toString());
  formData.append('auth', submissionAuth.auth);
  formData.append(field, files[field][fileIx]);

  // If this is the last file, let the server know that the submission is complete
  if (fileElementIx >= Object.keys(files).length - 1 && fileIx >= files[field].length - 1) formData.append('complete', 'true');

  return fetch(config.apiBaseUrl + '/forms/' + form.id + '/submissions/' + submissionAuth.id + '/files', {
    method: 'POST',
    body: formData
  }).then(response => response.json())
    .then((json:FormSubmissionResponse) => {
      if (!json.success) return json;
      onStatus(statusMessage, json.auth); // Update with any new auth
      if (!json.auth) throw new Error('No auth returned for file upload');

      if (fileIx + 1 < files[field].length) { // Upload the next file for the field
        return uploadSubmissionFiles(form, json.auth, files, onStatus, fileElementIx, fileIx + 1);
      } else if (fileElementIx + 1 < Object.keys(files).length) { // Upload the next field
        return uploadSubmissionFiles(form, json.auth, files, onStatus, fileElementIx + 1);
      } else { // Finish
        onStatus('Uploads complete');
        return json;
      }
    });
}

function validateForm(form: FormType, values: KeyedObject): string[] {
  const errors: string[] = [];

  for(let i = 0; i < form.elements.length; i++) {
    const element = form.elements[i];
    const required = element.required;
    const type = element.type;

    if (required && type === 'upload' && (!values[element.name] || values[element.name].length === 0)) {
      errors.push(element.label + ' is required');
    }
  }

  return errors;
}

/**
 * Separate the files from the values in the form data so that files can be subsequently uploaded
 * @param data
 */
function separateFilesAndValues(data: KeyedObject): [KeyedObject, KeyedObject<Array<File>>] {
  const values: KeyedObject = {};
  const files: KeyedObject<Array<File>> = {};

  for (var key in data) {
    if (Array.isArray(data[key]) && data[key].length > 0 && data[key][0] instanceof File) {
      values[key] = data[key].map((file: File, i: number) => {
        return {
          'file': file.name,
          'type': file.type,
          'size': file.size,
          'status': 'pending'
        };
      });
      files[key] = data[key];
    } else {
      values[key] = data[key];
    }
  }

  return [values, files];
}

export {getForm, submitForm};