import {FunctionComponent} from 'react';
import './Checkbox.css';
import classNames from 'classnames';

type CheckboxProps = {
  disabled?: boolean
  checked: boolean
  onToggle: () => void
};

const Checkbox: FunctionComponent<CheckboxProps> = ({disabled, checked, onToggle}) => {
  return (
    <div className={classNames('Checkbox', {checked, disabled})}
         onClick={() => {
           if (disabled) return;
           onToggle();
         }}
    >{checked?'X':<>&nbsp;</>}</div>
  );
}

export default Checkbox;