import {FunctionComponent} from 'react';
import {ElementProps} from '../../Element';
import ElementContainer from '../../ElementContainer';
import Select from '../../../form/Select';

type SelectInputProps = ElementProps;

const SelectInput: FunctionComponent<SelectInputProps> = ({disabled, element, value, onChange}) => {
  return (
    <ElementContainer element={element}>
      <Select disabled={disabled} value={value} choices={element.choices.map(choice => {
        return {label: choice.label, value: choice.value}
      })} onChange={onChange  }/>
    </ElementContainer>
  );
}

export default SelectInput;