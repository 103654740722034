import {FunctionComponent} from 'react';
import PageContainer from '../components/template/PageContainer';
import PageHeader from '../components/template/PageHeader';
import Container from '../components/common/Container';

const PageNotFound: FunctionComponent = () => {
  return (
    <PageContainer>
      <PageHeader/>
      <Container isSection={true}>
        <h1>Page Not Found</h1>
        <p>The page you were looking for could not be found.</p>
      </Container>
      {/*<PageFooter/>*/}
    </PageContainer>
  )
}

export default PageNotFound;
