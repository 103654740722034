import {FunctionComponent} from 'react';
import './Select.css';

type SelectProps = {
  choices: Array<{label: string, value: string}>
  // values: string[]
  value: string
  onChange: (value: string) => void
  disabled?: boolean
}
const Select: FunctionComponent<SelectProps> = ({disabled, choices, value, onChange}) => {
  return (
    <select disabled={disabled} className="Select" value={value} onChange={ev => onChange(ev.target.value)}>
      <option value="">Please Select</option>
      {choices.map((choice, index) => (<option key={index} value={choice.value}>{choice.label}</option>))}
    </select>
  );
}

export default Select;