const Prices = {
  residential: {
    singleSide: 595,
    dualAgency: 695
  },
  commercial: {
    singleSide: 650,
    dualAgency: 750,
  },
  vacantLand: 400,
  brokerUploadOnly: 150,
  concurrentContractedTitle: 89
}

export default Prices;
