import React, {FunctionComponent, useEffect, useState} from 'react';
import './ModalStack.css';
import {Modal, ModalManager, StackChangeEvent} from '../../services/modal-manager';

type ModalStackProps = {
    modalManager: ModalManager
};

const ModalStack: FunctionComponent<ModalStackProps> = ({modalManager}) => {
    const [stack, setStack] = useState<Array<Modal>>([]);

    useEffect(() => {
        function handleModalChange(ev: StackChangeEvent) {
            setStack([...ev.stack]); // Copy array to prevent assigning object to state stack
        }
        modalManager.onChange(handleModalChange);
        return () => {
            modalManager.removeOnChange(handleModalChange);
        };
    }, [modalManager]);

    if (stack.length === 0) return null;

    const currentModal = stack[stack.length-1];
    const showView = currentModal.view;

    return (
        <div className="Modals-overlay" onClick={() => {
            if (currentModal.options.canClickOutside) modalManager.popAll();
        }}>
            {showView}
        </div>
    );
};

export default ModalStack;
