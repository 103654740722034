import {FunctionComponent, PropsWithChildren} from 'react';
import Container from '../components/common/Container';
import PageContainer from '../components/template/PageContainer';
import PageFooter from '../components/template/PageFooter';
import PageHeader from '../components/template/PageHeader';
import CheckmarkBullet, {Type} from '../components/common/CheckmarkBullet';
import './Home.css';
import {imagePath} from "../utils/paths";
import ServiceSelector from '../components/services/ServiceSelector';
import Button from '../components/common/Button';
import {useNavigate} from 'react-router-dom';

type HomePageProps = PropsWithChildren;

const HomePage: FunctionComponent<HomePageProps> = (props) => {
  const nav = useNavigate();

  return (
    <PageContainer className="HomePage">
      <PageHeader/>

      <div className="HomeHeroContainer2">
        <div className="bg"/>
        <Container className="hero">
          <div className="row">
            <div className="col6">
              <h1><small>A Cost Effective Solution for</small> Real Estate Transactions</h1>
              {/*<div className="Home__pricing">$89.00 TRANSACTION PROCESSING FEE</div>*/}
              <div className="Home__pricing">
                <Button onClick={() => nav('/prices')}>
                  $89.00 TRANSACTION <span style={{whiteSpace: 'nowrap'}}>PROCESSING FEE</span>
                </Button>
              </div>
              <main>
                <ul className="unlist separated">
                  <li><CheckmarkBullet type={Type.Secondary}>TPS, Inc. is a leader in the real estate transaction
                    processing industry with more than a decade of experience.</CheckmarkBullet></li>
                  <li><CheckmarkBullet type={Type.Secondary}>We offer transaction processing services in California,
                    Arizona, Nevada, Oregon, Washington and Idaho.</CheckmarkBullet></li>
                  <li><CheckmarkBullet type={Type.Secondary}>Our dedicated team of professionals have immense expertise
                    and experience in assisting title, escrow and the agent with the processing of their real estate
                    transactions.</CheckmarkBullet></li>
                </ul>
              </main>
            </div>
            <div className="col6" style={{textAlign: 'center'}}>
              <img src={imagePath('/states.png')}
                   alt="Served states: California, Arizona, Nevada, Oregon, Washington and Idaho"
                   style={{maxWidth: 250}}/>
              <div className="HomeHeroContainer2__map-txt">
                Processing Services Available in<br/>CA, NV, AZ, OR, WA and ID
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Container className="choices" isSection={true}>
        <ServiceSelector/>
      </Container>

      <PageFooter/>
    </PageContainer>
  );
}

export default HomePage;
