import {FunctionComponent} from "react";
import './AgentServiceCards.css';
import ServiceCard2 from "./ServiceCard2";
import services from '../../config/services';
const AgentServiceCards: FunctionComponent = () => {
    return <div className="AgentServiceCards">
        {services.map(service => (
            <ServiceCard2 key={service.title}
                          title={service.title}
                          description={service.description}
                          image={service.image} />
        ))}
    </div>
};

export default AgentServiceCards;