import {FunctionComponent} from 'react';
import {ElementProps} from '../../Element';
import ElementContainer from '../../ElementContainer';
import Checkbox from '../../../form/Checkbox';
import './CheckboxInput.css';

type CheckboxInputProps = ElementProps;
type CheckboxOptionProps = {
  disabled?: boolean
  label: string
  checked: boolean
  onToggle: () => void
};
const CheckboxOption: FunctionComponent<CheckboxOptionProps> = ({disabled, label, checked, onToggle}) => {
  return (
      <>
        <Checkbox disabled={disabled} checked={checked} onToggle={onToggle}/> {label}
      </>
  );
}

function chunkArray<T=any>(array: Array<T>, chunkSize: number): Array<Array<T>> {
  var chunks = [];
  for (var i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

function columnizeArray<T=any>(array: Array<T>, numColumns: number): Array<Array<T>> {
  const columns = [];
  const columnSize = Math.ceil(array.length / numColumns);
  for (let i = 0; i < numColumns; i++) {
    columns.push(array.slice(i * columnSize, (i + 1) * columnSize));
  }
  return columns;
}

const CheckboxInput: FunctionComponent<CheckboxInputProps> = ({disabled, element, value, onChange}) => {
  const selected = Array.isArray(value) ? value : [];
  let numColumns = element.options.columns ?? 1;
  if (numColumns < 1) numColumns = 1;
  else if (numColumns > 4) numColumns = 4;
  // const columnClass = `col${12 / numColumns}`;
  const columns = columnizeArray(element.choices, numColumns);

  return (
    <ElementContainer element={element}>
      <div className="row CheckboxInput">
      {columns.map((columnChoices, index) => (
        <div key={index} className="CheckboxInput__column">
          {columnChoices.map((choice, index) => {
            const isChecked = selected.indexOf(choice.value) !== -1;
            return (
              <div key={index} className="CheckboxInput__row">
                <CheckboxOption disabled={disabled} checked={isChecked} label={choice.label} onToggle={() => {
                  const existingIx = selected.indexOf(choice.value);
                  if (existingIx === -1) {
                    onChange([...selected, choice.value]);
                  } else {
                    onChange(selected.filter((_, ix) => ix !== existingIx));
                  }
                }} />
              </div>
            );
          })}
        </div>
      ))}
      </div>
    </ElementContainer>
  );
}

export default CheckboxInput;