import {FunctionComponent} from 'react';
import {Route, Routes } from 'react-router-dom';
import FormHomePage from './FormHome';
import PageNotFound from '../PageNotFound';
import FormPage from './Form';

type FormsPageProps = {};

const FormsIndexPage: FunctionComponent<FormsPageProps> = () => {
  return (
    <Routes>
      <Route index element={<FormHomePage/>}/>
      <Route path="*" element={<FormPage/>}/>
    </Routes>
  );
}

export default FormsIndexPage;