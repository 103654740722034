import {FunctionComponent} from 'react';
import {ElementProps} from '../../Element';
import TextInput from '../../../form/TextInput';
import ElementContainer from '../../ElementContainer';

export type NameInputProps = ElementProps<{ first?: string, last?: string }>;

const NameInput: FunctionComponent<NameInputProps> = ({disabled, element, onChange, value}) => {
  return (
    <ElementContainer element={element}>
      <div className="row gapped">
        <div className="col6">
          <TextInput name={element.name + '_first'} disabled={disabled} value={value?.first ?? ''} onChange={first => onChange({...value, first})}/><br/>
          First
        </div>
        <div className="col6">
          <TextInput name={element.name + '_last'} disabled={disabled} value={value?.last ?? ''} onChange={last => onChange({...value, last})}/><br/>
          Last
        </div>
      </div>
    </ElementContainer>
  );
}

export default NameInput;