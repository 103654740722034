import {FunctionComponent} from 'react';
import PageContainer from '../../components/template/PageContainer';
import PageHeader from '../../components/template/PageHeader';
import PageFooter from '../../components/template/PageFooter';
import CircleIcon from '../../components/common/CircleIcon';
import OwnerServiceWizard from '../../components/services/OwnerServiceWizard';
import './Owners.css';

const OwnerServicesPage: FunctionComponent = () => {
  return (
    <PageContainer>
      <PageHeader/>
      {/*<Container isSection={true}>*/}
      {/*  <Button variation={ButtonVariation.Normal} onClick={() => nav('/services')}><i className="fa-solid fa-arrow-left"/> Back to Service Selection</Button>*/}
      {/*</Container>*/}
      <div className="OwnerServicesPage__hero m-t">

      </div>

      <h2 className="txt-center m-t"><CircleIcon iconClass="fa-solid fa-building"/> Escrow or Title Company Owner Benefits</h2>

      <OwnerServiceWizard/>
      <PageFooter/>
    </PageContainer>
  );
}

export default OwnerServicesPage;