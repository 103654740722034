import {imagePath} from "../utils/paths";
import {ServiceType} from "../types/service";

const services: ServiceType[] = [
    {
        title: 'Review',
        description: 'Review all contracts for signatures, terms, and forms required based on transaction type.',
        image: imagePath('/services/agent/pexels-vanessa-garcia-6325900-small.jpg')
    },
    {
        title: 'Terms / Timeline',
        description: 'Prepare terms, timeline, and contact sheet to distribute to all parties involved.',
        image: imagePath('/services/agent/pexels-pixabay-272976-small.jpg')
    },

    {
        title: 'Escrow / Title',
        description: 'Open escrow and/or title (upon agent request).',
        image: imagePath('/services/agent/pexels-cottonbro-studio-3943723-small.jpg')
    },

    {
        title: 'Notify',
        description: 'Notify all parties regularly the status of the file outlining completed and pending items.',
        image: imagePath('/services/agent/pexels-cottonbro-studio-5077054-small.jpg')
    },

    {
        title: 'Documents',
        description: 'Collect and facilitate completion of all required documents from all parties.',
        image: imagePath('/services/agent/AdobeStock_251700536.jpg')
    },

    {
        title: 'Disclosures',
        description: 'Prepare disclosures and send out to client(s) and/or agent(s).',
        image: imagePath('/services/agent/pexels-pixabay-247781-small.jpg')
    },

    {
        title: 'Report',
        description: 'Order NHD report, as applicable.',
        image: imagePath('/services/agent/pexels-energepiccom-175045-small.jpg')
    },

    {
        title: 'Deadlines',
        description: 'Manage deadlines and timeframes, notifying appropriate parties in advance when important due dates approach.',
        image: imagePath('/services/agent/pexels-stas-knop-1537268-small.jpg')
    },

    {
        title: 'Follow Up',
        description: 'Follow up regularly with agent(s), escrow and lender regarding file status.',
        image: imagePath('/services/agent/pexels-bruno-bueno-3854816-small.jpg')
    },

    {
        title: 'Warranty',
        description: 'Order home warranty, as applicable.',
        image: imagePath('/services/agent/istockphoto-1127838959-small.jpg')
    },

    {
        title: 'Compliance',
        description: 'Set up and manage file in broker required portal to ensure compliance.',
        image: imagePath('/services/agent/istockphoto-1181218189-small.jpg')
    }
];

export default services;