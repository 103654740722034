import {FunctionComponent} from 'react';
import PageContainer from '../components/template/PageContainer';
import PageHeader from '../components/template/PageHeader';
import PageFooter from '../components/template/PageFooter';
import Container from '../components/common/Container';
import {imagePath} from '../utils/paths';
import './Contact.css';

const ContactPage: FunctionComponent = () => {
    return (
        <PageContainer>
          <PageHeader/>
          <Container isSection={true} safeSides={true}>

            <div className="row gapped" style={{alignItems: 'center'}}>
              <div className="col4">
                <img src={imagePath('/contact/AdobeStock_497677788.jpg')} style={{width: '100%'}} alt="Laptop with contact icons"/>
              </div>
              <div className="col4 txt-center">
                <h1>We're Here to Help</h1>
                <div className="p"><i className="txt-medium fa-solid fa-phone"/> Phone: <a href="tel:8582210168" className="phone">(858) 221-0168</a></div>
                <div className="p"><i className="txt-medium fa-solid fa-fax"/> Fax: (858) 239-1530</div>
                <div className="p"><i className="txt-medium fa-solid fa-envelope"/> Email: <a href="mailto:support@mytpsinc.com?subject=Request+from+TPS+website">support@mytpsinc.com</a></div>
              </div>
              <div className="col4 txt-center">
                <img src={imagePath('/states.png')}
                     alt="Served states: California, Arizona, Nevada, Oregon, Washington and Idaho"
                     style={{width: 200, maxWidth: '100%'}}/>
                <div className="ContactPage--map-service-locations">
                  Processing Services Available in<br/>CA, NV, AZ, OR, WA and ID
                </div>
              </div>
            </div>
          </Container>
          <PageFooter/>
        </PageContainer>
    );
}

export default ContactPage;