import {FunctionComponent, PropsWithChildren} from 'react';
import './PageHeader.css';
import Button from "../common/Button";
import {useNavigate} from 'react-router-dom';
import Container from '../common/Container';

type HeaderProps = PropsWithChildren;

const PageHeader: FunctionComponent<HeaderProps> = () => {
  const nav = useNavigate();

  return (
    <Container>
      <div className="PageHeader">
        {/*<div>*/}
          <Button onClick={() => nav('https://www.mytpsinc.com/')}>
            <img src={process.env.PUBLIC_URL + '/assets/img/tpl/tps-logo.svg'} className="PageHeader__logo"
                 alt="TPS, Inc. Logo"/>
          </Button>
        {/*</div>*/}
        {/*<div>*/}
        {/*  <div className="PageHeader__phone"><a href="tel:8582210168" className="phone">(858) 221-0168</a></div>*/}
        {/*  <HamburgerMenu>*/}
        {/*    <div className="PageHeader__nav">*/}
        {/*      <ul>*/}
        {/*        <li><Button variation={ButtonVariation.LinkBlock} onClick={() => nav('/about')}>About Us</Button></li>*/}
        {/*        <li><Button variation={ButtonVariation.LinkBlock} onClick={() => nav('/value')}>Value</Button></li>*/}
        {/*        <li><Button variation={ButtonVariation.LinkBlock} onClick={() => nav('/services')}>Services</Button></li>*/}
        {/*        <li><Button variation={ButtonVariation.LinkBlock} onClick={() => nav('/prices')}>Pricing</Button></li>*/}
        {/*        <li><Button variation={ButtonVariation.LinkBlock} onClick={() => nav('/contact')}>Contact Us</Button></li>*/}
        {/*      </ul>*/}
        {/*    </div>*/}
        {/*  </HamburgerMenu>*/}
        {/*</div>*/}
      </div>
    </Container>
  );
}

export default PageHeader;
