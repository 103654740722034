import React, {FunctionComponent} from 'react';
import PageContainer from '../../components/template/PageContainer';
import PageHeader from '../../components/template/PageHeader';
import PageFooter from '../../components/template/PageFooter';
import Container from '../../components/common/Container';
import './Index.css';
import Padding from '../../components/common/Padding';
import CheckmarkBullet from '../../components/common/CheckmarkBullet';
import {imagePath} from '../../utils/paths';

const AboutIndexPage: FunctionComponent = () => (
  <PageContainer>
    <PageHeader/>

    <div className="AboutPage__our-story m-t bg-gray">
      <Container isSection={true}>
        <p className="txt-center" style={{lineHeight: '2.4em', fontWeight: 'bold'}}>
          <span style={{fontSize: '3em'}}>Our Growth Story</span><br/>
          <span style={{fontSize: '3em'}}>is One of Dedication</span></p>
        <div className="row gapped" style={{alignItems: 'center'}}>
          <div className="col6">
            <img src={imagePath('/about/AdobeStock_179531844.jpg')} style={{width: '100%', boxShadow: '0 0 10px #000'}} alt="Growth chart"/>
          </div>
          <div className="col6">
            <p style={{fontSize: '1.4em', lineHeight: '1.6em'}}>We started small which allowed us to continuously push our boundaries. We focused on our goals and leveraged our strengths to overcome challenges. As a result, over the last decade, we have mastered our craft and are proud of the service we provide to our clients.</p>
          </div>
        </div>
      </Container>
    </div>

    <div className="bg-green">
      <Padding>
      <Container>
        <p className="txt-center" style={{fontSize: '2em', lineHeight: '1.3em', marginTop: 0, fontWeight: 'bold'}}>Confidentiality is a Cornerstone<br/>
          to our Business Model.</p>
        <div className="row stacked-lists gapped">
          <div className="col6">
            <ul className="unlist separated">
              <li><CheckmarkBullet>We do not advertise the companies we work with.</CheckmarkBullet></li>
              <li><CheckmarkBullet>We operate mostly from referrals, which makes our continued growth even more satisfying.</CheckmarkBullet></li>
              <li><CheckmarkBullet>We are passionate professionals dedicated to providing exceptional services and memorable customer experiences.</CheckmarkBullet></li>
            </ul>
          </div>
          <div className="col6">
            <ul className="unlist separated">
              <li><CheckmarkBullet>We work with Real Estate Agents, Real Estate Brokers and Owners of Title and Escrow companies.</CheckmarkBullet></li>
              <li><CheckmarkBullet>We are the team behind you making sure you succeed.</CheckmarkBullet></li>
            </ul>
          </div>
        </div>
      </Container>
      </Padding>
    </div>

    <Container isSection={true} safeSides={true}>
      <div className="row gapped" style={{alignItems: 'center'}}>
        <div className="col6">
          <img src={imagePath('/about/AdobeStock_357128712.jpg')} style={{width: '100%'}} alt="Businesswoman smiling"/>
        </div>
        <div className="col6">
          <h2 className="txt-center">Mission Statement</h2>
          <p>Our mission at TPS, Inc. is to provide exceptional support to real estate professionals in managing the complex and time-consuming administrative tasks involved in real estate transactions. We aim to streamline the process of buying and selling properties, ensuring that every transaction is handled with the utmost care and attention to detail. Our goal is to take the burden of paperwork and coordination off our clients' shoulders, allowing them to focus on what they do best - serving their clients and growing their businesses. We are committed to delivering high-quality, efficient, and reliable services that exceed our clients' expectations and contribute to their success.</p>
        </div>
      </div>
    </Container>
    <Container safeSides={true}>
          <h2 className="txt-center">Vision Statement</h2>
          <div className="row gapped">
            <div className="col6">
              Our vision as a Transaction Coordinator company is to become the go-to partner for real estate professionals across the industry, known for our expertise, reliability, and commitment to excellence. We strive to innovate and stay ahead of the curve in the rapidly evolving world of real estate transactions, leveraging technology and best practices to provide the most efficient and effective services possible.
              <p>We envision a future where real estate professionals can trust us to handle all aspects of their transactions with precision and care, freeing them up to focus on building relationships with their clients and growing their businesses. Our ultimate goal is to help our clients achieve their goals and reach their full potential in the real estate industry, while providing an exceptional customer experience at every step of the way.</p>
              <p style={{fontWeight: 'bold'}}>Let us handle that – Trustworthy real estate transaction processing services!</p>
            </div>
            <div className="col6">
              <img src={imagePath('/about/AdobeStock_64925352.jpg')} style={{width: '100%'}} alt="Compass with arrow pointing to Excellence"/>
            </div>
          </div>
    </Container>
    <PageFooter/>
  </PageContainer>
);

export default AboutIndexPage;