import {FunctionComponent, PropsWithChildren} from 'react';
import './Button.css';
import classNames from "classnames";

export enum ButtonVariation {
    Default = 'default',
    Normal = 'normal',
    Link = 'link',
    LinkBlock = 'link block',
    Primary = 'primary',
    PrimaryInverted = 'primary inverted',
    Secondary = 'secondary',
    Danger = 'danger',
    PricingSpecial = 'pricing-special'
}

export type ButtonProps = PropsWithChildren & {
    onClick: () => void;
    variation?: ButtonVariation
    disabled?: boolean
};

const Button: FunctionComponent<ButtonProps> = ({children, disabled, onClick, variation}) => {
    if (!variation) variation = ButtonVariation.Default;

    const handleClick = () => {
        if (disabled) return;
        onClick();
    };

    return (
        <div className={classNames('Button', variation, {disabled})} onClick={handleClick}>
            {children}
        </div>
    );
};

export default Button;