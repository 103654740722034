import {BasicContactFormData} from '../types/contact-data';
import config from '../config/config';

type BaseContactFormDataType = {
  success: boolean
  errors: string[]
}
function sendContactData(contactData: BasicContactFormData): Promise<BaseContactFormDataType> { // const contactFormService
  return fetch(config.apiBaseUrl + '/contact', {
    method: 'post',
    body: JSON.stringify(contactData)
  }).then(response => response.json())
    .then((json:BaseContactFormDataType) => {
      return json;
    });
}

export default sendContactData;