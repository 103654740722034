import {FunctionComponent, PropsWithChildren} from 'react';
import './PageContainer.css';
import classNames from "classnames";

type PageContainerProps = PropsWithChildren & {
	className?: string;
}

const PageContainer: FunctionComponent<PageContainerProps> = ({children, className}) => {
	return (
		<div className={classNames('PageContainer', className)}>
			{children}
		</div>
	);
};

export default PageContainer;