import {FunctionComponent} from 'react';
import YesNoButtons from './YesNoButtons';
import './YesNoQuestion.css';

type YesNoQuestionProps = {
	iconClass?: string
	preText?: string;
	question: string;
	onYes: () => void;
	onNo: () => void;
};

const YesNoQuestion: FunctionComponent<YesNoQuestionProps> = ({
	iconClass,
	preText,
	question,
	onYes,
	onNo
}) => {
	return <div className="YesNoQuestion">
		{iconClass && <i className={iconClass}/>}
		<main>
			{preText && <div className="YesNoQuestion__pre-text">{preText}</div>}
			<div className="YesNoQuestion__question">{question}</div>
		</main>
		<YesNoButtons onYes={onYes} onNo={onNo}/>
	</div>
}

export default YesNoQuestion;