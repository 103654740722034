import {FunctionComponent} from 'react';
import {ElementProps} from '../Element';
import ElementContainer from '../ElementContainer';

export type HtmlElementProps = ElementProps;

const HtmlElement: FunctionComponent<HtmlElementProps> = ({element}) => {
  return (
    <ElementContainer element={element}>
      <div dangerouslySetInnerHTML={{__html: element.options.html}}/>
    </ElementContainer>
  );
}

export default HtmlElement;