import {FunctionComponent} from 'react';
import {ElementProps} from '../../Element';
import ElementContainer from '../../ElementContainer';
import TextInput from '../../../form/TextInput';

export type AddressType = {
  street1: string
  street2: string
  city: string
  state: string
  zip: string
}
type AddressInputProps = ElementProps<AddressType | undefined>;

const AddressInput: FunctionComponent<AddressInputProps> = ({disabled, element, value, onChange}) => {
  function updateAddress(key: keyof AddressType, newValue: string) {
    if (value === undefined) { // If the value is not already set, create a new object with the new keyed value
      onChange({...{street1: '', street2: '', city: '', state: '', zip: ''}, [key]: newValue});
      return;
    }
    onChange({...value, [key]: newValue});
  }
  return (
    <ElementContainer element={element}>
      <div className="row">
        <div className="col12">
          <TextInput disabled={disabled} value={value?.street1 ?? ''} onChange={street1 => updateAddress('street1', street1)}/><br/>
          <small>Street 1</small>
        </div>
      </div>
      <div className="row m-v">
        <div className="col12">
          <TextInput disabled={disabled} value={value?.street2 ?? ''} onChange={street2 => updateAddress('street2', street2)}/><br/>
          <small>Street 2</small>
        </div>
      </div>
      <div className="row gapped m-v">
        <div className="col4">
          <TextInput disabled={disabled} value={value?.city ?? ''} onChange={city => updateAddress('city', city)}/><br/>
          <small>City</small>
        </div>
        <div className="col4">
          <TextInput disabled={disabled} value={value?.state ?? ''} onChange={state => updateAddress('state', state)}/><br/>
          <small>State</small>
        </div>
        <div className="col4">
          <TextInput disabled={disabled} value={value?.zip ?? ''} onChange={zip => updateAddress('zip', zip)}/><br/>
          <small>Zip</small>
        </div>
      </div>
    </ElementContainer>
  );
}

export default AddressInput;