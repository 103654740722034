import {FunctionComponent} from 'react';
import './CircleIcon.css';

type CircleIconProps = {
    iconClass: string
};

const CircleIcon: FunctionComponent<CircleIconProps> = ({iconClass}) => {
    return (
        <div className="CircleIcon"><i className={iconClass}/></div>
    );
};

export default CircleIcon;