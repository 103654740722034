import {FunctionComponent, PropsWithChildren} from 'react';
import classNames from 'classnames';
import './Container.css';

type ContainerProps = PropsWithChildren & {
  className?: string;
  isSection?: boolean;
  safeSides?: boolean /** Whether to add margin to sides on smaller devices */
};

const Container: FunctionComponent<ContainerProps> = ({children, className, isSection, safeSides}) => {
  return (
    <div className={classNames('Container', {section: isSection, 'safe-sides': safeSides}, className)}>
      {children}
    </div>
  );
}

export default Container;
