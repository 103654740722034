import {FunctionComponent, PropsWithChildren} from 'react';
import classNames from "classnames";
import './Padding.css';

export enum PaddingVariation {
    Small = 'small',
    Default = 'default',
    Large = 'large'
}

type PaddingProps = PropsWithChildren & {
    variation?: PaddingVariation
};

const Padding: FunctionComponent<PaddingProps> = ({children, variation}) => {
    if (!variation) variation = PaddingVariation.Default;

    return (
        <div className={classNames('Padding', variation === PaddingVariation.Default ? null : variation)}>
            {children}
        </div>
    );
};

export default Padding;