import {FunctionComponent, PropsWithChildren} from 'react';
import PageHeader from '../../components/template/PageHeader';
import PageContainer from '../../components/template/PageContainer';
import Container from '../../components/common/Container';
import PageFooter from '../../components/template/PageFooter';

const FormHomePage: FunctionComponent<PropsWithChildren> = () => {
  return (
    <PageContainer>
      <PageHeader/>
      <Container isSection={true} safeSides={true}>
        <h1>Forms</h1>
        <p>Please use the form link that was sent to you.</p>
      </Container>
      <PageFooter/>
    </PageContainer>
  );
}

export default FormHomePage;