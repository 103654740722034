import {FunctionComponent, PropsWithChildren} from 'react';
import Container from '../common/Container';

import './PageFooter.css';
import Button, {ButtonVariation} from '../common/Button';
import {useNavigate} from 'react-router-dom';

type FooterProps = PropsWithChildren;

const PageFooter: FunctionComponent<FooterProps> = (props) => {
  const nav = useNavigate();
  return (
    <div className="PageFooter">
      <Container>
        <footer>
          <div className="gapped row">
            <div className="col4 txt-center">
              <img src={process.env.PUBLIC_URL + '/assets/img/tpl/tps-logo-green.svg'} className="FooterFooter__logo"
                   alt="TPS, Inc. Logo" style={{maxWidth: '100%'}}/>
            </div>
            <div className="col3">
              <div className="address">
                <div className="p"><i className="fa-solid fa-phone"/> Phone: <a href="tel:8582210168" className="phone">(858) 221-0168</a></div>
                <div className="p"><i className="fa-solid fa-fax"/> Fax: (858) 239-1530</div>
              </div>
            </div>
            {/*<div className="col5" style={{color: '#fff'}}>*/}
              {/*<h4>Our Story</h4>*/}
              {/*<p>Our growth story is one of dedication. We started small which allowed us to continuously push our*/}
              {/*  boundaries. We focused on our goals and leveraged our strengths to overcome challenges. As a result,*/}
              {/*  over the last decade, we have mastered our craft and are proud of the service we provide to our*/}
              {/*  clients.</p>*/}
              {/*<p>Confidentiality is a cornerstone to our business model. We do not advertise the companies we work with.*/}
              {/*  We operate mostly from referrals which makes our continued growth even more satisfying.</p>*/}
              {/*<p>We are passionate professionals dedicated to providing exceptional services and memorable customer*/}
              {/*  experiences.</p>*/}
              {/*<p>We work with Real Estate Agents, Real Estate Brokers and Owners of Title and Escrow companies.</p>*/}
              {/*<p>We are the team behind you making sure you succeed.</p>*/}
            {/*</div>*/}
            <div className="col3">
              <div className="p"><Button variation={ButtonVariation.Link} onClick={() => nav('/privacy')}>Privacy Policy</Button></div>
              <div className="p"><Button variation={ButtonVariation.Link} onClick={() => nav('/terms-of-use')}>Terms and Conditions</Button></div>
            </div>
            <div className="col3">
              <div className="p">&copy; Copyright {(new Date()).getFullYear()}</div>
            </div>
          </div>
        </footer>
      </Container>
    </div>
  );
}

export default PageFooter;
