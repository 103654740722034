import {FunctionComponent, useEffect, useState} from 'react';
import PageHeader from '../components/template/PageHeader';
import PageContainer from '../components/template/PageContainer';
import PageFooter from '../components/template/PageFooter';
import Container from '../components/common/Container';
import ErrorList from '../components/common/ErrorList';
import queryString from 'query-string';
import BusyIndicator from '../components/common/BusyIndicator';
import TextInput from '../components/form/TextInput';
import Button, {ButtonVariation} from '../components/common/Button';
import config from '../config/config';

type ResetPasswordPageProps = {};

enum ResetMode {
  Failed,
  Loading,
  ValidatingLink,
  Resetting,
  EnterPassword,
  Done
}

const ResetPasswordPage: FunctionComponent<ResetPasswordPageProps> = () => {
  const q = queryString.parse(window.location.search);
  const email = q.email ?? '';
  const key = q.key;
  const isFailed = (email === null || email.length === 0) || (key === null || key.length === 0);

  const [errors, setErrors] = useState<Array<string>>(isFailed ? ['The reset password link appears to be invalid.  Please try again.'] : []);
  const [mode, setMode] = useState<ResetMode>(isFailed ? ResetMode.Failed : ResetMode.Loading);
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    if (isFailed) {
      setMode(ResetMode.Failed);
      setErrors(['The link does not appear to be valid.'])
    } else {
      setMode(ResetMode.ValidatingLink);
      fetch(config.apiBaseUrl + '/auth/verifylink', {
        body: JSON.stringify({
          username: email,
          key
        }),
        method: 'POST'
      }).then(response => response.json())
        .then(response => {
          if (response.success) {
            setMode(ResetMode.EnterPassword);
          } else {
            setMode(ResetMode.Failed);
            setErrors(response.errors);
          }
        })
        .catch(e => {
          setMode(ResetMode.Failed);
          setErrors(['An unknown error occurred']);
        });
    }
  }, [isFailed, email, key]);

  return (
    <PageContainer>
      <PageHeader/>
      <Container>
        <ErrorList errors={errors}/>
        <p>Reset Password</p>
        {mode === ResetMode.Loading && <><BusyIndicator/> loading</>}
        {mode === ResetMode.ValidatingLink && <><BusyIndicator/> validating</>}
        {mode === ResetMode.Resetting && <><BusyIndicator/> resetting</>}
        {mode === ResetMode.Done && <>Your password has been reset.</>}
        {mode === ResetMode.EnterPassword && (
          <div style={{maxWidth: 200}}>
            <div className="m-b">
              <TextInput type="password" value={password} onChange={val => setPassword(val)}/>
            </div>
            <Button variation={ButtonVariation.Primary} onClick={() => {
              setMode(ResetMode.Resetting);
              fetch(config.apiBaseUrl + '/auth/resetpassword', {
                method: 'POST',
                body: JSON.stringify({
                  username: email,
                  key,
                  password
                })})
                .then(response => response.json())
                .then(response => {
                  if (response.success) {
                    setMode(ResetMode.Done);
                  } else {
                    setMode(ResetMode.EnterPassword);
                    setErrors(response.errors);
                  }
                })
                .catch(e => {
                  setMode(ResetMode.EnterPassword);
                  setErrors(['An unknown error occurred']);
                });
              // setMode(ResetMode.)
            }}>Update Password</Button>
          </div>
        )}
      </Container>
      <PageFooter/>
    </PageContainer>
  );
}

export default ResetPasswordPage;
