import {FunctionComponent} from 'react';
import './TextInput.css';
import classNames from "classnames";

type TextInputProps = {
    isError?: boolean
    placeholder?: string
    type?: string
    rows?: number // textarea
    name?: string
    value: string
    disabled?: boolean
    onChange: (value: string) => void
};

const TextInput: FunctionComponent<TextInputProps> = ({disabled, rows, isError, name, placeholder, type, value, onChange}) => {

    if (type === 'textarea') {
        const maxRows = 30;
        let useRows = rows ?? 2;
        const calcRows = value.split('\n').length;
        if (calcRows > useRows) {
            useRows = calcRows > maxRows ? maxRows : calcRows;
        }
        return <textarea disabled={disabled}
                         name={name}
                         id={name}
                         className={classNames('TextInput', {error: isError})}
                         onChange={e => onChange(e.target.value)}
                         placeholder={placeholder}
                         style={useRows === undefined ? undefined : {height: useRows * 1.3 + 'em'}}
                         value={value}/>;
    }

    return (
        <input type={type}
               name={name}
               id={name}
               disabled={disabled}
               className={classNames('TextInput', {error: isError})}
               value={value}
               onChange={e => onChange(e.target.value)}
               placeholder={placeholder}
        />
    );
};

export default TextInput;
