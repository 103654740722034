import {FunctionComponent} from 'react';
import ElementContainer from '../../ElementContainer';
import {ElementProps} from '../../Element';
import Text from '../../../form/TextInput';

type TextInputProps = ElementProps<string | undefined>;

const TextInput: FunctionComponent<TextInputProps> = ({disabled, element, onChange, value}) => {
  return (
    <ElementContainer element={element}>
      <Text name={element.name} disabled={disabled} type={element.type} value={value ?? ''} onChange={onChange}/>
    </ElementContainer>
  );
}

export default TextInput;