import {FunctionComponent} from 'react';
import './YesNoButtons.css';

type YesNoButtonsProps = {
	onYes: () => void;
	onNo: () => void;
}

const YesNoButtons: FunctionComponent<YesNoButtonsProps> = ({
	onYes,
	onNo
}) => {
	return <div className="YesNoButtons">
		<div className="YesNoButtons__button YesNoButtons__button--yes" onClick={() => onYes()}>Yes</div>
		<div className="YesNoButtons__button YesNoButtons__button--no" onClick={() => onNo()}>No</div>
		<div className="YesNoButtons__or">OR</div>
	</div>;
};

export default YesNoButtons;
