import {FunctionComponent} from 'react';
import {FormElementType} from '../../types/forms';
import ElementResolver from '../../services/element-resolver';
import {FormSubmission} from './FormBuilder';

export type ElementProps<T = any, E extends FormElementType = FormElementType> = {
  disabled: boolean
  submission: FormSubmission
  element: FormElementType<E>
  value: T
  onChange: (value: T) => void
};

const Element: FunctionComponent<ElementProps> = ({disabled, element, submission, value, onChange}) => {
  const resolver = ElementResolver.create();
  const Component = resolver.resolveElement<ElementProps>(element.type);
  return Component === undefined ? (
    <div>Unknown element: {element.type}</div>
  ) : (
    <Component submission={submission}
               disabled={disabled}
               element={element}
               value={value === undefined ? resolver.defaultValue(value) : value}
               onChange={onChange}/>
  );
}

export default Element;