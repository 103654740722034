import {FunctionComponent, useState} from 'react';
import {FormType} from '../../types/forms';
import Element, {ElementProps} from './Element';
import FormRow from '../form/FormRow';
import update from 'immutability-helper';
import Button, {ButtonVariation} from '../common/Button';
import Label from './Label';
import ErrorList from '../common/ErrorList';
import {SubmissionAuth, submitForm} from '../../services/form-submissions';
import BusyIndicator from '../common/BusyIndicator';

type FormProps = {
  form?: FormType
  onSubmitted: (submission: FormSubmission) => void
};

export type FormSubmission = {
  auth?: SubmissionAuth
  values: { [key: string]: any }
};

type SubmissionStatusState = {
  isSubmitting: boolean
  message: string
  errors: string[]
  fieldErrors: { [key: string]: string[] }
}

const FormElementRow: FunctionComponent<ElementProps> = ({
                                                           disabled,
                                                           element,
                                                           submission,
                                                           onChange,
                                                           value
                                                         }) => {
  return (
    <FormRow>
      {element.label ? <Label required={element.required}>{element.label}</Label> : null}

      <Element disabled={disabled}
               submission={submission}
               element={element}
               value={value}
               onChange={onChange}
      />
    </FormRow>
  );
}
const FormBuilder: FunctionComponent<FormProps> = ({form, onSubmitted}) => {
  const [status, setStatus] = useState<SubmissionStatusState>({
    isSubmitting: false,
    message: '',
    errors: [],
    fieldErrors: {}
  });

  const [submission, setSubmission] = useState<FormSubmission>({
    values: {}
  });
  if (!form) return null;

  return (
    <div>
      <h1>{form.title}</h1>
      <ErrorList errors={status.errors}/>
      {form.elements.map((element, index) => {
        return (
          <FormElementRow key={index}
                          disabled={status.isSubmitting}
                          submission={submission}
                          element={element}
                          value={element.name ? submission.values[element.name] : null}
                          onChange={value => setSubmission(update(submission, {
                            values: {
                              [element.name]: {$set: value}
                            }
                          }))}/>
        );
      })}

      <ErrorList errors={status.errors}/>

      <Button disabled={status.isSubmitting}
              variation={ButtonVariation.Primary}
              onClick={() => {
                setStatus(update(status, {
                  errors: {$set: []},
                  fieldErrors: {$set: {}},
                  isSubmitting: {$set: true},
                  message: {$set: ''}
                }));

                submitForm(form, submission.values, (statusMessage, auth) => {
                  // Update status
                  setStatus(update(status, {
                    isSubmitting: {$set: true},
                    message: {$set: statusMessage},
                    errors: {$set: []},
                    fieldErrors: {$set: {}}
                  }));
                  // Update submission values
                  setSubmission(prevSubmissionState => {
                    return update(submission, {
                      auth: {$set: auth ?? prevSubmissionState.auth}, // Update if set or keep existing
                    });
                  });
                }, submission.auth).then(response => {
                  if (response.success) {
                    setStatus(update(status, {
                      errors: {$set: []},
                      fieldErrors: {$set: {}},
                      message: {$set: 'Submission successful'},
                      isSubmitting: {$set: false}
                    }));
                    onSubmitted(submission);
                  } else {
                    setStatus(update(status, {
                      isSubmitting: {$set: false},
                      errors: {$set: response.errors},
                      fieldErrors: {$set: response.fieldErrors}
                    }));
                  }
                }).catch((e) => {
                  setStatus(update(status, {
                    isSubmitting: {$set: false},
                    message: {$set: ''},
                    errors: {$set: ['An error occurred submitting the form: ' + e.message]},
                  }));
                });
              }}
      >{status.isSubmitting ? <>Submitting...</> : <>Submit</>}</Button>

      {/* Submission Status */}
      {status.isSubmitting && <BusyIndicator/>}
      {status.message.length > 0 && <span> {status.message}</span>}
    </div>
  );
}

export default FormBuilder;