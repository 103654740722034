import {FunctionComponent, PropsWithChildren} from 'react';
import './Label.css';

type LabelProps = PropsWithChildren<{
  required?: boolean
}>

const Label: FunctionComponent<LabelProps> = ({children, required}) => {
  const requiredElement = required ? <span className="Label__required">*</span> : null;
  return (
    <div className="Label">{children}{requiredElement}</div>
  );
}

export default Label;