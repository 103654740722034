import {FunctionComponent} from 'react';
import './PriceChart.css';
import Prices from '../../config/prices';

type PriceChartProps = {};

const PriceChartContent: FunctionComponent<PriceChartProps> = () => {
  const fmt = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return (
    <table className="PriceChart">
      <thead>
      <tr>
        <th colSpan={2}>Transaction Coordinator / Standard Rates</th>
      </tr>
      </thead>
      <tbody>
      <tr className="section"><th colSpan={2}>
        Residential
      </th></tr>
      <tr className="indent">
        <th>
          Single Agency Transaction<br/>
          <em>(Representing Buyer or Seller)</em>
        </th>
        <td>{fmt.format(Prices.residential.singleSide)}</td>
      </tr>
      <tr className="indent">
        <th>
          Dual Agency Transaction<br/>
          <em>(Representing BOTH Buyer and Seller [Different agent, but same brokerage does not apply])</em>
        </th>
        <td>{fmt.format(Prices.residential.dualAgency)}</td>
      </tr>
      <tr className="section">
        <th colSpan={2}>Commercial</th>
      </tr>
      <tr className="indent">
        <th>
          Single Agency Transaction<br/>
          <em>(Representing Buyer or Seller)</em>
        </th>
        <td>{fmt.format(Prices.commercial.singleSide)}</td>
      </tr>
      <tr className="indent">
        <th>
          Dual Agency Transaction<br/>
          <em>(Representing BOTH Buyer and Seller [Different agent, but same brokerage does not apply])</em>
        </th>
        <td>{fmt.format(Prices.commercial.dualAgency)}</td>
      </tr>
      <tr className="section"><th colSpan={2}>Other</th></tr>
      <tr className="indent">
        <th>Multi Units exceeding 4 units:<br/><span style={{fontWeight: 'normal'}}>Standard Rate + $35 for each additional unit</span></th>
        <td>$35 each</td>
      </tr>
      {/*<tr className="indent">*/}
      {/*  <th>Vacant Land</th>*/}
      {/*  <td>{fmt.format(Prices.vacantLand)}</td>*/}
      {/*</tr>*/}
      <tr className="indent">
        <th>Broker Upload Only</th>
        <td>{fmt.format(Prices.brokerUploadOnly)}</td>
      </tr>
      <tr className="indent">
        <th>If concurrently using a contracted title/escrow company</th>
        <td className="txt-warning">{fmt.format(Prices.concurrentContractedTitle)}</td>
      </tr>
      </tbody>
    </table>
  );
}

export default PriceChartContent;
