import {FunctionComponent, useEffect, useState} from 'react';
import {FormType} from '../../types/forms';
import {getForm} from '../../services/form-submissions';
import FormBuilder from '../../components/form-builder/FormBuilder';
import PageContainer from '../../components/template/PageContainer';
import PageHeader from '../../components/template/PageHeader';
import Container from '../../components/common/Container';
import BusyIndicator from '../../components/common/BusyIndicator';

const FormPage: FunctionComponent = () => {
  const formId = 'process-order';
  const [form, setForm] = useState<FormType | null | undefined>();
  const [showThankYou, setShowThankYou] = useState<boolean>(false);
  useEffect(() => {
    getForm(formId).then(form => {
      setForm(form);
    }).catch(e => {
      alert('Failed to load form: ' + e.message);
      setForm(null);
    });
  }, []);

  return (
    <PageContainer>
      <PageHeader/>
      <Container safeSides={true}>
        <div style={{margin: '0 auto', fontSize: '0.9rem'}}>
          <div className="m-t">
            {form === undefined && <div><BusyIndicator/> loading form...</div>}
            {form === null && <div>Failed to load form</div>}
            {form !== null && form !== undefined && (
              showThankYou ? (
                <>
                  <h1>{form.title}</h1>
                  <p>Submission sent.</p>
                </>
              ) : (
                <FormBuilder form={form} onSubmitted={submission => {
                  setShowThankYou(true);
                }}/>
              )
            )}
          </div>
        </div>
      </Container>
    </PageContainer>
  );
}

export default FormPage;