import {FunctionComponent, useState} from 'react';
import {ElementProps} from '../../Element';
import ElementContainer from '../../ElementContainer';
import Upload from '../../../form/Upload';
import {FormElementType} from '../../../../types/forms';

type UploadInputProps = ElementProps<any, FormElementType<{
  accept: string
}>>;

const UploadFileInput: FunctionComponent<UploadInputProps> = ({disabled, element, value, onChange}) => {
  const [dragOver, setDragOver] = useState(false);
  const [dropped, setDropped] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  return (
    <ElementContainer element={element}>
      <Upload
        disabled={disabled}
        onDragOver={isDraggingOver => {
          setDragOver(isDraggingOver);
        }}
        onDrop={wasDropped => {
          setDropped(wasDropped);
        }}
        onFilesSelected={files => {
          console.log('Files:', files);
          setFiles(files);
          onChange(files);
      }}
              accept={element.accept}>
        <div style={{
          padding: 50,
          textAlign: 'center',
          border: disabled ? '1px solid #ccc' : '1px solid #000',
          width: '100%',
          cursor: disabled ? 'normal' : 'pointer',
          color: disabled ? '#ccc' : undefined
        }}>
          {files.length > 0 ? 'Upload ' + files[0].name : 'Click to Upload'}<br />
          {/*Drag: {dragOver ? 'Yes' : 'No'}<br/>*/}
          {/*Dropped: {dropped ? 'Yes' : 'No'}*/}
        </div>
      </Upload>
    </ElementContainer>
  );
}

export default UploadFileInput;