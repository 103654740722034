import {FunctionComponent} from 'react';
import HtmlElement from '../components/form-builder/elements/HtmlElement';
import NameInput from '../components/form-builder/elements/input/NameInput';
import TextInput from '../components/form-builder/elements/input/TextInput';
import AddressInput from '../components/form-builder/elements/input/AddressInput';
import SelectInput from '../components/form-builder/elements/input/SelectInput';
import DateInput from '../components/form-builder/elements/input/DateInput';
import CheckboxInput from '../components/form-builder/elements/input/CheckboxInput';
import TextAreaInput from '../components/form-builder/elements/input/TextAreaInput';
import UploadFileInput from '../components/form-builder/elements/input/UploadFileInput';
import HeaderElement from '../components/form-builder/elements/HeaderElement';

class ElementResolver {
  private elementTypes: {[key: string]: FunctionComponent<any>}
  private defaultValues: {[key: string]: any}

  constructor() {
    this.elementTypes = {};
    this.defaultValues = {};
  }

  registerElementType<T, V=any>(type: string, element: FunctionComponent<T>, defaultValue?: V) {
    this.elementTypes[type] = element;
    if (defaultValue !== undefined) this.defaultValues[type] = defaultValue;
  }

  resolveElement<T>(elementType: string): FunctionComponent<T> {
    return this.elementTypes[elementType];
  }

  defaultValue<T>(elementType: string): T {
    return this.defaultValues[elementType];
  }

  static create() {
    const resolver = new ElementResolver();
    // Display elements
    resolver.registerElementType('html', HtmlElement);
    resolver.registerElementType('header', HeaderElement);
    // resolver.registerElementType('section', SectionElement);
    // resolver.registerElementType('text', TextElement, '');

    // Input elements
    resolver.registerElementType('input-text', TextInput, '');
    resolver.registerElementType('input-email', TextInput, '');
    resolver.registerElementType('input-name', NameInput, {first: '', last: ''});
    resolver.registerElementType('input-address', AddressInput, {street1: '', street2: '', city: '', state: '', zip: ''});
    resolver.registerElementType('input-select', SelectInput, '');
    resolver.registerElementType('input-date', DateInput, '');
    resolver.registerElementType('input-checkbox', CheckboxInput, []);
    resolver.registerElementType('input-textarea', TextAreaInput, '');
    resolver.registerElementType('input-upload', UploadFileInput);
    return resolver;
  }
}

export default ElementResolver;
