import {FunctionComponent, PropsWithChildren} from 'react';
import './CheckmarkBullet.css';
import classNames from "classnames";

export enum Type {
    Primary,
    Secondary
}
type CheckmarkBulletProps = PropsWithChildren & {
    type?: Type
};

const CheckmarkBullet: FunctionComponent<CheckmarkBulletProps> = ({children, type}) => {
    if (type === undefined) type = Type.Primary;

    return (
        <div className={classNames('CheckmarkBullet', {
            'CheckmarkBullet--secondary': type === Type.Secondary
        })}>
            <div className="CheckmarkBullet__check"><i className="fa-solid fa-circle-check"></i></div>
            <div>{children}</div>
        </div>
    );
}

export default CheckmarkBullet;
