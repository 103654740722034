import {FunctionComponent, PropsWithChildren, useContext} from 'react';
import PageContainer from '../../components/template/PageContainer';
import PageHeader from '../../components/template/PageHeader';
import PageFooter from '../../components/template/PageFooter';
import Container from '../../components/common/Container';
import './Prices.css';
import classNames from 'classnames';
import Button, {ButtonVariation} from '../../components/common/Button';
import {imagePath} from '../../utils/paths';
import AppContext from '../../contexts/AppContext';
import ContactFormModal from '../../components/modal/ContactFormModal';
import prices from '../../config/prices';

type PricesPageProps = {};

const PriceWidget: FunctionComponent<PropsWithChildren<{
  title?: string,
  price: string,
  inverted?: boolean
  qualifier?: string
  emailSubject?: string
}>> = ({title, price, inverted, emailSubject, qualifier}) => {
  const appContext = useContext(AppContext);
  return (
    <div className={classNames('PricesPage__service-price', {inverted})}>
      <main>
        {qualifier && (<div className="PricesPage__service-price__qualifier">{qualifier}</div>)}
        {title && <div className="PricesPage__service-price__title">{title}</div>}
        {price && <div className="PricesPage__service-price__price">{price}</div>}
        <Button variation={ButtonVariation.Secondary} onClick={() => {
          appContext.modalManager.pushModal(<ContactFormModal onDone={() => appContext.modalManager.popModal()} subject={emailSubject ?? (title ?? price)}/>);
        }}>Start</Button>
      </main>
    </div>
  )
};

const PricesPage: FunctionComponent<PricesPageProps> = () => {
  const fmt = Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency'
  })
  return (
    <PageContainer>
      <PageHeader/>
      <h1 className="txt-center">Service Pricing</h1>
      <div className="PricesPage__hero-image">
        <main style={{backgroundImage: 'url(' + imagePath('/pricing/1025742772.jpg') + ')'}}/>
      </div>
        <Container safeSides={true}>
          <PriceWidget price={'Home of the $' + prices.concurrentContractedTitle + ' Fee'} inverted={true} emailSubject={'$' + prices.concurrentContractedTitle + ' TC Fee'}/>
        </Container>

      <Container safeSides={true} isSection={true}>

        <div className="flex flex-wrap m-h-auto flex-centered">
          <div className="col6">
            <PriceWidget title="Single Agency Transaction*" price={fmt.format(prices.residential.singleSide)} emailSubject={'Single Agency Transaction @ ' + fmt.format(prices.residential.singleSide)} qualifier="Residential (1-4 Units), Vacant Land,Reo"/>
          </div>
          <div className="col6">
            <PriceWidget title="Dual Agency Transaction**" price={fmt.format(prices.residential.dualAgency)} emailSubject={'Dual Agency Transaction @  ' + fmt.format(prices.residential.dualAgency)} qualifier="Residential (1-4 Units), Vacant Land,Reo" />
          </div>
          <div className="col6">
            <PriceWidget title="Single Agency Transaction*" price={fmt.format(prices.commercial.singleSide)} emailSubject={'Single Agency Transaction @ ' + fmt.format(prices.commercial.singleSide)} qualifier="Commercial"/>
          </div>
          <div className="col6">
            <PriceWidget title="Dual Agency Transaction**" price={fmt.format(prices.commercial.dualAgency)} emailSubject={'Dual Agency Transaction @  ' + fmt.format(prices.commercial.dualAgency)} qualifier="Commercial" />
          </div>
          <div className="col6">
            <PriceWidget title="Broker Upload Only" price={fmt.format(prices.brokerUploadOnly)} emailSubject={'Broker Upload Only @ ' + fmt.format(prices.brokerUploadOnly)}/>
          </div>
          <div className="col6">
            <PriceWidget title="If concurrently using a contracted title/escrow company" price={fmt.format(prices.concurrentContractedTitle)} emailSubject={'Concurrently using contracted title/escrow company @ ' + fmt.format(prices.concurrentContractedTitle)}/>
          </div>
        </div>

        <p>
          Multi Units exceeding 4 units: Standard Rate + $35 for each additional unit<br/>
          * Representing Buyer or Seller<br/>
          ** Representing BOTH Buyer and Seller (Different agent, but same brokerage does not apply)
        </p>
      </Container>
      <PageFooter/>
    </PageContainer>
  );
}

export default PricesPage;
