import React, {FunctionComponent} from 'react';
import './App.css';
import {Route, Routes} from 'react-router-dom';
import PageNotFound from './pages/PageNotFound';
import ModalStack from './components/modal/ModalStack';
import ModalManager from './services/modal-manager';
import AppContext from './contexts/AppContext';
import FormsIndexPage from './pages/forms/Index';
import AboutIndexPage from './pages/about/Index';
import ContactPage from './pages/Contact';
import ValuePage from './pages/services/Value';
import PricesPage from './pages/services/Prices';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import TermsOfUsePage from './pages/TermsOfUsePage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import HomePage from './pages/Home';
import ServiceIndexPage from './pages/services/Index';

const App: FunctionComponent = () => {
  const modalManager = ModalManager.instance();
  return (
    <div className="App">
      <AppContext.Provider value={{
        modalManager
      }}>
        <Routes>
          <Route path="/">
            <Route path="/about" element={<AboutIndexPage/>}/>
            <Route path="/contact" element={<ContactPage/>}/>
            <Route path="/services/*" element={<ServiceIndexPage/>}/>
            <Route path="/value" element={<ValuePage/>}/>
            <Route path="/prices" element={<PricesPage/>}/>
            <Route path="/privacy" element={<PrivacyPolicyPage/>}/>
            <Route path="/terms-of-use" element={<TermsOfUsePage/>}/>
            <Route path="/reset-password" element={<ResetPasswordPage/>}/>
            <Route index element={<HomePage/>}/>
            <Route index path="*" element={<PageNotFound/>}/>

            <Route path="/forms/*" element={<FormsIndexPage/>}/>
            {/*<Route index element={<PageNotFound/>}/>*/}
            {/*<Route path="*" element={<PageNotFound/>}/>*/}
          </Route>
        </Routes>
        <ModalStack modalManager={modalManager}/>
      </AppContext.Provider>
    </div>
  );
}

export default App;
