import {createContext} from 'react';
import ModalManager from '../services/modal-manager';

type AppContextType = {
  modalManager: ModalManager;
}

const AppContext = createContext<AppContextType>({
  modalManager: ModalManager.instance(),
});

export default AppContext;