import {FunctionComponent} from 'react';
import PageContainer from '../components/template/PageContainer';
import PageHeader from '../components/template/PageHeader';
import PageFooter from '../components/template/PageFooter';
import Container from '../components/common/Container';

type TermsOfUseProps = {};

const TermsOfUsePage: FunctionComponent<TermsOfUseProps> = () => {
  return (
    <PageContainer>
      <PageHeader/>
      <Container isSection={true}>
        <h1>Transaction Processing Services, Inc. (Referred to as TPS for this agreement) TERMS OF USE AGREEMENT</h1>

        <ol>
          <li>
            <strong>PLEASE READ THIS:</strong><br/>
            <p>This Terms of Use Agreement ("Agreement” or "TOU”) is made by and between Transaction Processing Services, Inc, a Nevada Corporation, and you ("You,” "Your” or "User”). This Agreement contains the terms and conditions that govern Your use of this Website.</p>
            <p>BY ACCESSING OR ATTEMPTING TO INTERACT WITH ANY PART OF THIS WEBSITE, OR OTHER TPS SOFTWARE, SERVICES, WEBSITES OR ANY OF TPS LICENSEES’ SERVICES (COLLECTIVELY "SERVICES”), YOU AGREE THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THIS AGREEMENT AND TPS <strong>PRIVACY NOTICE</strong>. IF YOU DO NOT AGREE TO BE BOUND BY THESE AGREEMENTS, DO NOT ACCESS OR USE ANY PART OF THIS WEBSITE.</p>
            <p>TPS RESERVES THE RIGHT, FROM TIME TO TIME, WITH OR WITHOUT NOTICE TO YOU, TO MAKE CHANGES TO THIS AGREEMENT IN TPS SOLE DISCRETION. CONTINUED USE OF ANY PART OF THIS WEBSITE CONSTITUTES YOUR ACCEPTANCE OF SUCH CHANGES. THE MOST CURRENT VERSION OF THIS AGREEMENT, WHICH SUPERSEDES ALL PREVIOUS VERSIONS, CAN BE REVIEWED BY CLICKING ON THE TERMS OF USE HYPERLINK LOCATED AT THE BOTTOM OF EVERY PAGE ON THE TPS WEBSITE.</p>
          </li>
          <li>
            <strong>ACCESS TO THIS SITE</strong>
            <p>THIS SITE IS NOT INTENDED FOR CHILDREN UNDER 18 YEARS OF AGE. CHILDREN UNDER THE AGE OF 18 ARE NOT ELIGIBLE TO USE THE TPS WEBSITE. TPS DOES NOT KNOWINGLY COLLECT PERSONAL INFORMATION FROM CHILDREN UNDER 18.</p>
            <p>To access this site or some of the resources it offers, you may need to provide certain information, including personally identifiable information (PI).  PI is information identifiable to any person, including, but not limited to, information that relates to a person’s name, health, finances, education, business, use or receipt of governmental services or other activities, addresses, telephone numbers, social security numbers, driver license numbers, other identifying numbers, and any financial identifiers. It is a condition of your use of this site that all the information You provide on this site will be correct, current, and complete. If TPS believes the information You provide is not correct, current, or complete, TPS has the right to refuse You access to this site or any of its resources, and to terminate or suspend Your access at any time.</p>
          </li>
          <li>
            <strong>RESTRICTIONS ON USE</strong>
            <p>You may use this site for purposes expressly permitted by this site. As a condition of Your use of TPS website(s), You warrant to TPS that You will not use the website(s) for any purpose that is unlawful or prohibited by these terms, conditions, and notices. For example, you may not (and may not authorize any party to) (i) co-brand this site, or (ii) frame this site, or (iii) download any content from this site without the express prior written permission of an authorized representative of TPS  For purposes of the TOU, co-branding means to display a name, logo, trademark, or other means of attribution or identification of any party in such a manner as is reasonably likely to give a user the impression that such other party has the right to display, publish, or distribute this site or content accessible within this site. You agree to cooperate with TPS to prevent or remedy any unauthorized use. In addition, you may not use TPS website(s) in any manner which could disable, overburden, damage, or impair the website(s) or interfere with any other party’s use and enjoyment of the website(s). You may not obtain or attempt to obtain any materials, content, or information through any means not intentionally made available or provided for through the website(s).</p>
          </li>
          <li>
            <strong>PERSONAL AND NON-COMMERCIAL USE LIMITATION</strong>
            <p>TPS website(s) are for Your personal and non-commercial use, unless otherwise specified. You may not use this site for any other purpose, including any commercial purpose, without TPS express prior written consent. You may not modify, copy, distribute, display, send, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, content, software, products, or services obtained from or otherwise connected to TPS website(s).</p>
          </li>
          <li>
            <strong>PROPRIETARY INFORMATION</strong>
            <p>The material and content accessible from this site, and any other website owned, operated, licensed, or otherwise controlled by TPS (the "Content”) is the proprietary information of TPS or the party that provided or licensed the Content to TPS, whereby such providing party retains all right, title, and interest in the Content. Accordingly, the Content may not be copied, distributed, republished, uploaded, posted, or transmitted in any way without the prior written consent of TPS, except that You may print out a copy of the Content solely for Your personal use. In doing so, you may not remove or alter, or cause to be removed or altered, any copyright, trademark, trade name, service mark, or any other proprietary notice or legend appearing on any of the Content. Modification or use of the Content except as expressly provided in the TOU violates TPS intellectual property rights. Neither title nor intellectual property rights are transferred to You by access to this site.</p>
          </li>
          <li>
            <strong>LINKS TO THIRD-PARTY WEBSITES</strong>
            <p>This site may link to other sites which are not maintained by TPS ("Third-Party Provider”). Links to such sites are provided as a service to users and may be affiliated with this site or TPS Links are accessed at the user’s own risk, and TPS makes no representations or warranties about the content, completeness or accuracy of the sites linked to or from this site. TPS provides links as a convenience, and the inclusion of any link to a third-party site does not imply endorsement by TPS of that site or any association with its operators.</p>
            <p>Third-Party Providers are solely responsible for their services. You are responsible for Your use and submission of PI to any third-party, and Your dealings or business conducted with any third party arising in connection with the Services are solely between You and such third party. Your use of third-party sites, services, or products may be subject to associated third-party terms of use and privacy policies or other agreements, which You are solely responsible for complying with. TPS does not endorse, and takes no responsibility for such products, services, Web sites, and materials, or a Third-Party Provider’s use of Your User Information. By using a tool that allows for User Information to be transferred, you agree that TPS may transfer the applicable User Information or other information to the applicable third parties, which are not under the control of TPS.  If You submit a contact form or otherwise indicate Your interest in contacting a Third-Party Provider, you may receive telemarketing calls from the Third-Party Provider using the contact information You provided. Third-Party Providers may keep Your contact information and any other User Information received by the Third-Party Provider in processing a contact or other request form. TPS is not responsible for any damages or costs of any type arising out of or in any way connected with Your dealings with these third parties.</p>
          </li>
          <li>
            <strong>USE OF SERVICES</strong>
            <p>TPS websites may contain mobile applications ("App”), comment boxes, forums, bulletin board services, chat areas, message boards, news groups, communities, personal web pages, calendars, and/or other message or communication facilities designed to allow You to communicate with the Internet community, with a group, or third-party vendors/service providers.  If You comply with the TOU, TPS grants You a non-exclusive, limited, revocable, personal, non-transferable license to use the Services, and to download and use any App on Your mobile device in object code form, for Your personal use. You agree to use the Services only to post, send and receive messages and content that are proper and related to the Service. Users agree to adhere the TOU agreement as well as TPS Rules of Conduct Agreement, if posted, when using TPS Services.</p>
            <p>When using the Services, you agree that You will not post, send, submit, publish, or transmit in connection with this site any material that:</p>
            <ul>
              <li>You do not have the right to post, including proprietary material of any third party, such as files containing software or other material protected by intellectual property laws (or by rights of privacy or publicity).</li>
              <li>Advocates illegal activity or discusses an intent to commit an illegal act.</li>
              <li>Is vulgar, obscene, pornographic, or indecent.</li>
              <li>Does not pertain directly to this site.</li>
              <li>Threatens or abuses others, libels, defames, invades privacy, stalks, is obscene, pornographic, racist, abusive, harassing, threatening or offensive.</li>
              <li>Seeks to exploit or harm children by exposing them to inappropriate content, asking for personally identifiable details or otherwise.</li>
              <li>Harvests or otherwise collects information about others, including e-mail addresses, without their consent.</li>
              <li>Violates any law or may be considered to violate any law.</li>
              <li>Impersonates or misrepresents Your connection to any other entity or person or otherwise manipulates headers or identifiers to disguise the origin of the content.</li>
              <li>Falsifies or deletes any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is permissibly uploaded.</li>
              <li>Advertises any commercial endeavor (e.g., offering for sale products or services) or otherwise engages in any commercial activity (e.g., conducting raffles or contests, displaying sponsorship banners, and/or soliciting goods or services) except as may be specifically authorized on this site.</li>
              <li>Solicits funds, advertisers, or sponsors.</li>
              <li>Includes programs that contain viruses, worms and/or Trojan horses or any other computer code, files or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications.</li>
              <li>Disrupts the normal flow of dialogue, causes a screen to scroll faster than other users can type, or otherwise act in a way which affects the ability of other people to engage in real time activities via this site.</li>
              <li>Includes MP3 format files.</li>
              <li>Amounts to a pyramid or other like scheme, including contests, chain letters, and surveys.</li>
              <li>Disobeys any policy or regulations including any code of conduct or other guidelines, established from time to time regarding use of this site or any networks connected to this site; or</li>
              <li>Contains hyper-links to other sites that contain content that falls within the descriptions set forth above.</li>
            </ul>
            <p>TPS reserves the right to monitor use of this site to determine compliance with the TOU, as well as the right to remove or refuse any information for any reason. TPS reserves the right to terminate Your access to any or all the Services at any time without notice for any reason whatsoever. TPS also always reserves the right to disclose any information as necessary to satisfy any applicable law, regulation, legal process, or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in its sole discretion. Materials may be subject to posted limits on use, reproduction and/or dissemination and You are responsible for abiding by such limitations with respect to Your submissions, including any downloaded materials.</p>
            <p>Notwithstanding these rights, you remain solely responsible for the content of Your submissions. You acknowledge and agree that neither TPS nor any third party that provides Content to TPS will assume or have any liability for any action or inaction by TPS or such third party with respect to any submission. TPS cautions You against giving out any personally identifying information about yourself or Your children in using the Services except as needed to access the Services. TPS does not control or endorse all content, messages or information found in the Services and, consequently, TPS specifically disclaims any liability with respect to the Services and any actions resulting from Your participation in any of the Services. Managers and hosts are not authorized TPS spokespersons, and their views do not necessarily reflect those of TPS.</p>
          </li>
          <li>
            <strong>DISCLAIMER</strong>
            <p>You understand that TPS cannot and does not guarantee or warrant that files available for downloading from the Internet will be free of viruses, worms, Trojan horses, or other code that may manifest contaminating or destructive properties. You are responsible for implementing sufficient procedures and checkpoints to satisfy Your requirements for accuracy of data input and output, and for maintaining a means external to this site for any reconstruction of any lost data. TPS does not assume any responsibility or risk for Your use of the Internet.</p>
            <p>The Content is not necessarily complete and up-to-date and should not be used to replace any written reports, statements, or notices provided by TPS.</p>
            <p>YOUR USE OF THIS SITE IS AT YOUR OWN RISK. THE CONTENT IS PROVIDED AS IS AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED. TPS DISCLAIMS ALL WARRANTIES, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT. TPS DOES NOT WARRANT THAT THE FUNCTIONS OR CONTENT CONTAINED IN THIS SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. TPS DOES NOT WARRANT OR MAKE ANY REPRESENTATION REGARDING USE, OR THE RESULT OF USE, OF THE CONTENT IN TERMS OF ACCURACY, RELIABILITY, OR OTHERWISE. THE CONTENT MAY INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS, AND TPS MAY MAKE CHANGES OR IMPROVEMENTS AT ANY TIME. YOU, AND NOT TPS, ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION IN THE EVENT OF ANY LOSS OR DAMAGE ARISING FROM THE USE OF THIS SITE OR ITS CONTENT. TPS MAKES NO WARRANTIES THAT YOUR USE OF THE CONTENT WILL NOT INFRINGE THE RIGHTS OF OTHERS AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH CONTENT.</p>
            <p>All the information in this site, whether historical in nature or forward-looking, speaks only as of the date the information is posted on this site, and TPS does not undertake any obligation to update such information after it is posted or to remove such information from this site if it is not, or is no longer accurate or complete.</p>
          </li>
          <li>
            <strong>LIMITATION ON LIABILITY</strong>
            <p>TPS, ITS SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS WILL NOT BE LIABLE FOR ANY INCIDENTAL, DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR OTHER DAMAGES, INCLUDING LOSS OF REVENUE OR INCOME, PAIN AND SUFFERING, EMOTIONAL DISTRESS, OR SIMILAR DAMAGES, EVEN IF TPS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL THE COLLECTIVE LIABILITY OF TPS AND ITS SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS TO ANY PARTY (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE) EXCEED THE GREATER OF $100 OR THE AMOUNT YOU HAVE PAID TO TPS FOR THE APPLICABLE CONTENT OR SERVICE OUT OF WHICH LIABILITY AROSE.</p>
          </li>
          <li>
            <strong>TERMINATION OR RESTRICTION OF ACCESS</strong>
            <p>TPS reserves the right, in its sole discretion, to terminate Your access TPS websites and the related services or any portion thereof at any time, without notice.  If TPS terminates Your services, it reserves the right to exercise whatever means necessary to prevent unauthorized access to the Services.</p>
          </li>
          <li>
            <strong>INDEMNITY</strong>
            <p>You will indemnify and hold TPS, its subsidiaries, affiliates, licensors, content providers, service providers, employees, agents, officers, directors, and contractors (the "Indemnified Parties”) harmless from any breach of the TOU by You, including any use of Content other than as expressly authorized in the TOU. You agree that the Indemnified Parties will have no liability in connection with any such breach or unauthorized use, and You agree to indemnify all resulting loss, damages, judgments, awards, costs, expenses, and attorneys’ fees of the Indemnified Parties in connection therewith. You will also indemnify and hold the Indemnified Parties harmless from and against any claims brought by third parties arising out of Your use of the information accessed from this site.</p>
          </li>
          <li>
            <strong>TRADEMARKS AND COPYRIGHTS</strong>
            <p>The trademarks, trade names, service marks, and logos ("Trademarks”) appearing on this Website, including without limitation TPS and all other content on this Website ("Copyrights”), including all page headers, custom graphics, button icons, and scripts are copyrighted works of TPS and may not be copied, imitated, or used, in whole or in part, without the prior written permission of TPS. The rights in any third-party trademarks or copyrighted works on this Website are retained by their respective owners. Nothing in this Agreement shall confer any right of ownership of any of the Trademarks or Copyrights to You. Further, nothing in this Agreement shall be construed as granting, by implication, estoppel or otherwise, any license or right to use any Trademark or Copyright without the express written permission of TPS. The misuse of the Trademark or Copyrighted works displayed in this site, or any other content on the site, is strictly prohibited and may expose You to liability.</p>
          </li>
          <li>
            <strong>COPYRIGHT INFRINGEMENT</strong>
            <p><strong>Notice and Takedown Procedure</strong></p>
            <p>TPS expeditiously responds to valid notices of copyright infringement that adhere to the requirements set forth in the Digital Millennium Copyright Act (DMCA). If You believe that Your work has been copied on this site in a way that constitutes copyright infringement, you may provide TPS Designated Agent (listed below) with a notice that contains all six points enumerated below (preferably via email).</p>
            <p>Upon receipt of a valid notice, TPS will remove or disable access to the allegedly infringing content as well as make a good-faith attempt to contact the owner or administrator of the affected content so they may counter-notice pursuant to Sections 512(g)(2) and (3) of the DMCA.</p>

            <ul>
              <li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest.</li>
              <li>A description of the copyrighted work that You claim has been infringed upon.</li>
              <li>A description of where the material that You claim is infringing is located on the site, including the auction ID number, if applicable.</li>
              <li>Your address, telephone number, and e-mail address.</li>
              <li>A statement by You that You have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and,</li>
              <li>A statement by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are the copyright owner or authorized to act on the copyright owner’s behalf.</li>
            </ul>
            <p>Be aware that a notice must contain all six points for TPS to act. All other notices will be ignored.</p>
            <p><strong>Designated Agent:</strong></p>
            <p>Transaction Processing Services, Inc. (TPS) Designated Agent may be contacted at:</p>
            <p>
              Higgs Fletcher & Mack LLP<br/>
              401 West A Street, Suite 2600<br/>
              San Diego, CA 92101.<br/>
              e-mail: <a href="mailto:copyrights@higgslaw.com">copyrights@higgslaw.com</a>
            </p>
            <p><strong>Counter-Notification Procedure</strong></p>
            <p>The provider of the allegedly infringing content may make a counter-notification pursuant to sections 512(g)(2) and (3) of the DMCA. To file a counter-notification with TPS, please provide TPS Designated Agent (listed above) with the following information (preferably via email):</p>
            <ol>
              <li>Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled.</li>
              <li>Your name, address, and telephone number.</li>
              <li>The following statement: "I consent to the jurisdiction of Federal District Court for the [insert the federal judicial district in which Your address is located."</li>
              <li>The following statement: "I will accept service of process from [insert the name of the person who submitted the infringement notification] or his/her agent."</li>
              <li>The following statement: "I swear, under penalty of perjury, that I have a good faith belief that the affected material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled"; and</li>
              <li>Your signature, in physical or electronic form.</li>
            </ol>
            <p>Upon receipt of a counter-notification containing all six points, TPS will promptly provide the person who provided the original takedown notification with a copy of the counter-notification and inform that person that TPS will replace the removed material or cease disabling access to it in 10 business days.</p>
            <p>Finally, if TPS Designated Agent then receives notification within 14 days of the counter notification from the person who submitted the original takedown notification that an action has been filed seeking a court order to restrain the alleged infringer from engaging in infringing activity relating to the material on TPS site, then TPS will once again remove the file from its site.</p>
            <p><strong>Repeat Infringers</strong></p>
            <p>In accordance with Section 512(i)(1)(a) of the DMCA, TPS will, in appropriate circumstances, disable and/or terminate the accounts of users who are repeat infringers.</p>
          </li>
          <li>
            <strong>ARBITRATION</strong>
            <p>All disputes arising out of or relating to use of the site or this TOU or Your use of the Services will be exclusively resolved under confidential binding arbitration held in San Diego, California, or wherever the Parties may agree, before and in accordance with the Rules of the American Arbitration Association.  The arbitrator’s award will be binding and may be entered as a judgment in any court of competent jurisdiction.  To the fullest extent permitted under applicable law, no arbitration under the TOU will be joined to an arbitration involving any other party subject to the TOU, whether through class arbitration proceedings or otherwise.  Notwithstanding the foregoing, TPS will have the right to seek injunctive or other equitable relief in state of federal court located in San Diego to enforce these terms and prevent an infringement of a third party’s rights.  In the event equitable relief is sought, each party hereby irrevocably submits to the personal jurisdiction of such court.</p>
          </li>
          <li>
            <strong>SECURITY</strong>
            <p>You are prohibited from using any services or facilities provided in connection with this site to compromise security or tamper with system resources and/or accounts. The use or distribution of tools designed for compromising security (e.g., password guessing programs, cracking tools, or network probing tools) is strictly prohibited. If You become involved in any violation of system security, TPS reserves the right to release Your details to system administrators at other sites to assist them in resolving security incidents. TPS reserves the right to investigate suspected violations of the TOU.</p>
            <p>TPS reserves the right to fully cooperate with any law enforcement authorities or court order requesting or directing TPS to disclose the identity of anyone posting any e-mail messages, or publishing or otherwise making available any materials that are believed to violate the TOU. BY ACCEPTING THIS AGREEMENT, YOU WAIVE AND HOLD HARMLESS TPS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY TPS DURING OR AS A RESULT OF ITS INVESTIGATIONS AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER TPS OR LAW ENFORCEMENT AUTHORITIES.</p>
          </li>
          <li>
            <strong>MISCELLANEOUS</strong>
            <p>TPS will not be liable for failing to perform according to the TOU by the occurrence of any event beyond its reasonable control, including, without limitation, a labor disturbance, Internet outage or interruption of service, fire, terrorism, pandemic, natural disaster, or war.</p>
            <p>The TOU will be governed and interpreted pursuant to the laws of California, United States of America, notwithstanding any principles of conflicts of law. You specifically consent to personal jurisdiction in California in connection with any dispute between You and TPS arising out of the TOU or pertaining to the subject matter hereof. The parties to the TOU each agree that the exclusive venue for any dispute between the parties arising out of the TOU will be in the state and federal courts in San Diego County, California.</p>
            <p><strong>Class Action Waiver.</strong> By agreeing to the TOU and/or accessing the TPS site, you hereby irrevocably waive any right You may have to join claims with those of others in the others in the form of a class action or similar procedural device. Any claims arising out of, relating to, or connected with the TOU must be asserted individually.</p>
            <p>If any part of the TOU is unlawful, void, or unenforceable, that part will be deemed severable and will not affect the validity and enforceability of any remaining provisions.</p>
            <p>You agree that no joint venture, partnership, employment, or agency relationship exists between You and TPS because of this agreement or use of TPS website(s).</p>
            <p>The TOU constitutes the entire agreement among the parties relating to this subject matter and supersede all prior or contemporaneous communications and proposals, whether electronic, oral, or written between a user and TPS with respect to TPS website.</p>
            <p>A printed version of the TOU and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</p>
            <p>Notwithstanding the foregoing, any additional terms and conditions on this site will govern the items to which they pertain.</p>
            <p>Last update: June 2023.</p>
          </li>
        </ol>
      </Container>
      <PageFooter/>
    </PageContainer>
  );
}

export default TermsOfUsePage;