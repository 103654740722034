import {FunctionComponent} from 'react';
import {ElementProps} from '../../Element';
import ElementContainer from '../../ElementContainer';
import TextInput from '../../../form/TextInput';

type DateInputProps = ElementProps;

const DateInput: FunctionComponent<DateInputProps> = ({disabled, element, value, onChange}) => {
  return (
    <ElementContainer element={element}>
      <TextInput disabled={disabled} type="date" value={value} onChange={onChange}/>
    </ElementContainer>
  );
}

export default DateInput;