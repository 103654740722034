import {FunctionComponent, PropsWithChildren} from 'react';
import classNames from 'classnames';
import './Box.css';

type BoxProps = PropsWithChildren & {
	className?: string
};

const Box: FunctionComponent<BoxProps> = ({children, className}) => {
	return <div className={classNames('Box', 'Box--padded', className)}>{children}</div>
}

export default Box;