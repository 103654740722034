import {ChangeEventHandler, FunctionComponent, PropsWithChildren, useRef} from 'react';

type UploadProps = PropsWithChildren<{
  disabled: boolean
  accept?: string
  allowMultiple?: boolean
  onFilesSelected: (files: Array<File>) => void
  onDragOver?: (isDragOver: boolean) => void
  onDrop?: (wasDropped: boolean) => void
}>;

const Upload: FunctionComponent<UploadProps> = (props) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const handleChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    if (props.disabled) return;
    if (!ev.target.files || ev.target.files.length === 0) return;
    const fileList: Array<File> = [];
    for(let i=0; i < ev.target.files.length; i++) {
      fileList.push(ev.target.files[i]);
    }
    props.onFilesSelected(fileList);
  };

  const handleClick = () => {
    if (props.disabled) return;
    fileRef.current?.click();
    // if (props.onClick) props.onClick();
  };

  const handleDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
    if (props.disabled) return;
    // console.log('Drag: ', ev);
  }

  const handleDrop = (ev: React.DragEvent<HTMLDivElement>) => {
    if (props.disabled) return;
    // console.log('Drop: ', ev);
  }
  return (
    <form encType="multipart/form-data">
      <div onClick={handleClick} onDragOver={handleDragOver} onDrop={handleDrop}>
        <input type="file"
               ref={fileRef}
               name="file"
               accept={props.accept || '*'}
               style={{display: 'none'}}
               multiple={props.allowMultiple === true}
               onChange={handleChange}
        />
        {props.children}
      </div>
    </form>
  );
}

export default Upload;