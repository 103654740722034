import {FunctionComponent} from 'react';
import {ElementProps} from '../../Element';
import ElementContainer from '../../ElementContainer';
import TextInput from '../../../form/TextInput';

type TextAreaInputProps = ElementProps;

const TextAreaInput: FunctionComponent<TextAreaInputProps> = ({disabled, element, value, onChange}) => {
  return (
    <ElementContainer element={element}>
      <TextInput name={element.name} disabled={disabled} type="textarea" value={value ?? ''} onChange={onChange} rows={10}/>
    </ElementContainer>
  );
}

export default TextAreaInput;