import {FunctionComponent} from 'react';
import {ElementProps} from '../Element';
import ElementContainer from '../ElementContainer';

type HeaderElementProps = ElementProps;

const HeaderElement: FunctionComponent<HeaderElementProps> = ({element, value, onChange}) => {
  let text = <>{element.text}</>;
  switch(element.level) {
    case 1:
      text = <h1>{text}</h1>;
      break;
    case 2:
      text = <h2>{text}</h2>;
      break;
    case 3:
      text = <h3>{text}</h3>;
      break;
    case 4:
      text = <h4>{text}</h4>;
      break;
    // case 4:
    //   text = <h4>{text}</h4>;
    //   break;
    // case 5:
    //   text = <h5>{text}</h5>;
    //   break;
    // case 6:
    //   text = <h6>{text}</h6>;
    //   break;
    default:
      text = <p>{text}</p>
      break;
  }
  return (
    <ElementContainer element={element}>
      {text}
    </ElementContainer>
  );
}

export default HeaderElement;