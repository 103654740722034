import {FunctionComponent, PropsWithChildren} from 'react';
import {FormElementType} from '../../types/forms';
import './ElementContainer.css';

type FormWrapProps = PropsWithChildren<{
  element: FormElementType
}>;

const ElementContainer: FunctionComponent<FormWrapProps> = ({children, element}) => {
  return (
    <div className="ElementContainer">
      {children}
    </div>
  );
}

export default ElementContainer;