import {FunctionComponent} from 'react';
import './ErrorList.css';

type ErrorListProps = {
  errors: string[]
};

const ErrorList: FunctionComponent<ErrorListProps> = ({errors}) => {
  if (errors.length === 0) return null;

  return (
    <ul className="ErrorList">
      {errors.map(msg => <li key={msg}>{msg}</li>)}
    </ul>
  );
}

export default ErrorList;