import {FunctionComponent, PropsWithChildren, useContext} from 'react';
import PageContainer from '../../components/template/PageContainer';
import PageHeader from '../../components/template/PageHeader';
import PageFooter from '../../components/template/PageFooter';
import Container from '../../components/common/Container';
import CheckmarkBullet from '../../components/common/CheckmarkBullet';
import AppContext from '../../contexts/AppContext';
import Modal from '../../components/modal/Modal';
import ModalContent from '../../components/modal/ModalContent';
import './Value.css';
import {imagePath} from '../../utils/paths';

type ValuePageProps = {};

const BenefitCard: FunctionComponent<PropsWithChildren<{title: string, iconClass: string}>> = ({title, iconClass, children}) => {
  const appContext = useContext(AppContext);
  return (
    <div>
      <div className="ValuePage__benefit-card-front clickable" onClick={() => {
        appContext.modalManager.pushModal(
          <Modal className="ValuePage__benefit-modal">
            <ModalContent>
              <div className="ValuePage__benefit-card-back">
                <header><i className={iconClass}/> {title}</header>
                {children}
              </div>
            </ModalContent>
          </Modal>
        )
      }}>
        <main>
          <i className={iconClass}/>
          <div>{title}</div>
        </main>
      </div>
    </div>
  )
}

const ValuePage: FunctionComponent<ValuePageProps> = () => {
  return (
    <PageContainer>
      <PageHeader/>
      <div className="flex flex-centered" style={{backgroundImage: 'url(' + imagePath('/value/AdobeStock_224106781.jpg') + ')', backgroundSize: 'cover', height: 500, backgroundPosition: 'top center'}}>
        <Container isSection={true} safeSides={true} className="no-m-b">
          <h1 className="txt-center txt-green no-m-b">
            What is the value in using a<br/>
            TPS Transaction Coordinator<br />
            for a Real Estate closing?
          </h1>
        </Container>
      </div>
      <Container safeSides={true}>
        <div className="row" style={{alignItems: 'center'}}>
          <div className="col6">
            <p>It's no secret that using a Transaction Coordinator can provide significant value to real estate professionals and their clients by helping to streamline the transaction process and ensure that every aspect of the transaction is handled efficiently and accurately.</p>
            <p>It's imperative that while you are selecting a Transaction Coordinator you consider both their experience and their qualifications.</p>
          </div>
          <div className="col6" style={{minHeight: 300, backgroundImage: 'url(' + imagePath('/value/AdobeStock_568056624.jpg') + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}}/>
        </div>
      </Container>
      <div className="ValuePage__values">
        <h2 className="txt-center txt-white txt-large">Key Benefits</h2>
        <p className="txt-center txt-green">Here are some of the key benefits you can expect when using a TPS Transaction Coordinator:</p>
        <Container>
          <div className="row" style={{justifyContent: 'center'}}>
            <div className="col3">
              <BenefitCard iconClass="fa-solid fa-clock" title="Time savings">
                Coordinating a real estate transaction involves numerous time-consuming administrative tasks. By
                delegating these tasks to a Transaction Coordinator, real estate professionals can focus on serving
                their clients and growing their businesses, while still ensuring that the transaction runs smoothly.
              </BenefitCard>
            </div>
            <div className="col3">
              <BenefitCard iconClass="fa-solid fa-circle-exclamation" title="Reduced risk of errors">
                Real estate transactions involve complex legal and financial documents that require careful attention to
                detail. A Transaction Coordinator can help ensure that all necessary documents are completed accurately
                and on time, reducing the risk of errors or oversights that could delay or even derail the transaction.
              </BenefitCard>
            </div>
            <div className="col3">
              <BenefitCard iconClass="fa-solid fa-walkie-talkie" title="Improved communication">
                Transactions involve multiple parties, including buyers, sellers, lenders, inspectors, and appraisers,
                among others. A Transaction Coordinator can help facilitate communication between these parties,
                ensuring that everyone is on the same page and that any issues or concerns are addressed promptly.
              </BenefitCard>
            </div>
            <div className="col3">
              <BenefitCard iconClass="fa-solid fa-hands-holding" title="Peace of mind">
                Knowing that an experienced and knowledgeable professional is overseeing the transaction can provide
                real estate professionals and their clients with peace of mind, reducing stress and uncertainty
                throughout the process.
              </BenefitCard>
            </div>
          </div>

          <div className="row" style={{justifyContent: 'center'}}>
            <div className="col3">
              <BenefitCard iconClass="fa-solid fa-gear" title="Personalization">
                Just like any other customer experience, personalization can play a crucial role in the transaction
                coordinator experience. Understanding our clients' preferences, priorities, and concerns can help us
                personalize our approach and provide a more tailored experience. For example, some clients may require
                more frequent updates and check-ins, while others may prefer a more hands-off approach.
              </BenefitCard>
            </div>
            <div className="col3">
              <BenefitCard iconClass="fa-solid fa-lock" title="Security">
                With Scammers at an all-time high, you can rest assured that TPS utilizes the highest security standards
                available to help protect your client and their greatest asset.
              </BenefitCard>
            </div>
            <div className="col3">
              <BenefitCard iconClass="fa-solid fa-people-group" title="Processing Team">
                Although you will always have one point of contact throughout your transactions, TPS Transaction
                Coordinators are backed by a behind the scenes support team.
              </BenefitCard>
            </div>
          </div>
          <div className="flex flex-wrap ValuePage__benefits">
          </div>
        </Container>
      </div>
      <Container safeSides={true}>
        <p className="txt-center flex" style={{gap: 20, margin: '50px 0', fontSize: '1.2em', alignContent: 'center', justifyContent: 'center', fontWeight: 'bold', textTransform: 'uppercase', flexWrap: 'wrap'}}>
          <div><CheckmarkBullet>Streamline Processes.</CheckmarkBullet></div>
          <div><CheckmarkBullet>Reduce Stress.</CheckmarkBullet></div>
          <div><CheckmarkBullet>Improve Outcomes.</CheckmarkBullet></div>
        </p>
        <p>Overall, having a team of support helps to streamline processes, reduce stress, and improve outcomes. Therefore providing additional support to make sure your TC is effective and efficient in the areas that matter most.</p>
      </Container>
      <PageFooter/>
    </PageContainer>
  );
}

export default ValuePage;