import {FunctionComponent} from 'react';
import waitingAnimation from '../../assets/img/Spinner-1s-50px.svg';

type BusyIndicatorProps = {};

const BusyIndicator: FunctionComponent<BusyIndicatorProps> = () => {

  return (
    <img src={waitingAnimation} style={{verticalAlign: 'middle'}} alt="Busy indicator"/>
  );
}

export default BusyIndicator;