import {FunctionComponent} from 'react';
import PageContainer from '../../components/template/PageContainer';
import PageHeader from '../../components/template/PageHeader';
import PageFooter from '../../components/template/PageFooter';
import Container from '../../components/common/Container';
import ServiceSelector from '../../components/services/ServiceSelector';
import CheckmarkBullet from '../../components/common/CheckmarkBullet';
import Padding from '../../components/common/Padding';
import {imagePath} from '../../utils/paths';

type ServicesPageProps = {};

const ServicesHomePage: FunctionComponent<ServicesPageProps> = () => {
  return (
    <PageContainer>
      <PageHeader/>
      <div className="bg-green m-v">
        <Padding>
          <Container className="">
            <div className="row gapped" style={{alignItems: 'center'}}>
              <div className="col6">
                <h1 className="no-m-b" style={{fontSize: '3em'}}>Our Services</h1>
                <p style={{fontSize: '1.4em'}}>Transaction Processing Services, Inc. (TPS) offers services for:</p>

                <div style={{fontSize: '1.4em'}}>
                  <div className="flex txt-left">
                    <div style={{margin: '0 auto'}}>
                      <CheckmarkBullet>Brokers</CheckmarkBullet>
                      <CheckmarkBullet>Agents</CheckmarkBullet>
                      <CheckmarkBullet>Escrow and Title Companies</CheckmarkBullet>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col6">
                <img src={imagePath('/services/2024994257.jpg')} style={{width: '100%', maxWidth: 500}} alt="Processing documents diagram"/>
              </div>
            </div>
          </Container>
        </Padding>
      </div>
      <div>
        <Container isSection={true} safeSides={true}>
          <p className="txt-center" style={{}}>Please choose what services you are interested in learning more about below:</p>
          <ServiceSelector/>
        </Container>
      </div>
      <PageFooter/>
    </PageContainer>
  );
}

export default ServicesHomePage;